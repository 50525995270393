import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";

import '../../styles/activation.css';
import { activateAccount, sendActivationCode } from "../../api/authAPI";

export default function ActivateAccount() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [isCodeSent, setCodeSend] = useState(false);
    const [success, setSuccess] = useState(false);

    function sendCode(email) {
        sendActivationCode(email).then(res => {
            if (res.status == 200) {
                setEmail(email);
                setCodeSend(true);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function activate(email, code) {
        activateAccount(email, code).then(res => {
            if (res.status == 200) {
                setSuccess(true);
                navigate('/');
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const ActivateCodeSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('activation.errorEmailRequired'))
            .email(t('activation.errorEmailInvalid')),
    });

    const ActivateAccountSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('ativation.errorEmailRequired'))
            .email(t('activation.errorEmailInvalid')),
        code: Yup.string()
            .required(t('activation.errorCodeRequired')),
    });

    return (
        <div className="activation-container">
            {!isCodeSent && <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={ActivateCodeSchema}
                onSubmit={values => {
                    sendCode(values.email);
                }}>
                {({ touched, errors }) => (
                    <Form className="activation-form-container">
                        <div className="activation-form-header">
                            <h1>{t("activation.title")}</h1>
                        </div>
                        <div className="activation-form-body">
                            <div className="activation-form-input">
                                <Field type="text" placeholder={t("activation.emailPlaceholder")} className="activation"
                                    name="email" />
                                {errors.email && touched.email ? (<div className="activation-form-input-error">
                                    {errors.email}</div>) : null}
                            </div>
                        </div>
                        <div className="activation-form-footer">
                            <button className="activation-form-button" type="submit">{t("activation.sendCodeButton")}</button>
                        </div>
                    </Form>
                )}
            </Formik>}

            {isCodeSent && <Formik
                initialValues={{
                    email: email,
                    code: '',
                }}
                validationSchema={ActivateAccountSchema}
                onSubmit={values => {
                    activate(values.email, values.code);
                }}>
                {({ touched, errors }) => (
                    <Form className="activation-form-container">
                        <div className="activation-form-header">
                            <h1>{t("activation.title")}</h1>
                        </div>
                        <div className="activation-form-body">
                            <div className="activation-form-input">
                                <Field type="text" placeholder={t("activation.emailPlaceholder")} className="email"
                                    name="email" />
                                {errors.email && touched.email ? (<div className="activation-form-input-error">
                                    {errors.email}</div>) : null}
                            </div>
                            <div className="activation-form-input">
                                <Field type="number" placeholder={t("activation.codePlaceholder")} className="code"
                                    name="code" />
                                {errors.code && touched.code ? (<div className="activation-form-input-error">
                                    {errors.code}</div>) : null}
                            </div>
                        </div>
                        <div className="activation-form-footer">
                            <button className="activation-form-button" type="submit">{t("activation.activationButton")}</button>
                        </div>
                    </Form>
                )}
            </Formik>}
        </div>
    );
}