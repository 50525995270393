import { configureStore } from '@reduxjs/toolkit'
import eventReducer from './events'
import settingsReducer from './settings';
import authReducer from './auth';
import modalReducer from './modal';
import calendarReducer from './calendar';
import iconReducer from './icons';
import colorReducer from './colors';
import categoryReducer from './categories';
import thematicsReducer from './thematics';

export default configureStore({
    reducer: {
        events: eventReducer,
        thematics: thematicsReducer,
        settings: settingsReducer,
        auth: authReducer,
        modal: modalReducer,
        calendar: calendarReducer,
        icons: iconReducer,
        colors: colorReducer,
        categories: categoryReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActionPaths: [
                    "payload.date",
                ],
                ignoredPaths: [
                    "calendar.dayDate",
                    "calendar.weekDate",
                    "calendar.monthDate",
                    "calendar.halfYearDate",
                    "calendar.yearDate",
                    "events.events",
                    "modal.date",
                ]
            },
        }),
})