import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/auth";
import { removeUser, removeToken } from '../../functions/TokenFunctions';

function Logout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    removeToken();
    removeUser();
    dispatch(logout());

    navigate("/");

    return (
        <></>
    );
}
export default Logout;