import dayjs from "dayjs";
import { useState } from "react";
import classNames from "classnames";
import { useDrop } from "react-dnd";
import useEventListener from "@use-it/event-listener";
import { useDispatch, useSelector } from "react-redux";

import { isCurrentDay } from "../index";
import { EventItemRounded } from "../Event";
import { openModal } from "../../../store/modal";
import { DraggableTypes } from "../../utility/DraggableTypes";

import 'dayjs/locale/ru';
import "./DayAsList.scss";
import { getEventsForDate } from "../../../store/events";

function DayAsList(props) {
    const date = dayjs(props.date).format('YYYY-MM-DD');
    const events = useSelector(state => getEventsForDate(state, date))
    const dispatch = useDispatch();
    const [keyPressed, setKeyPressed] = useState(false);

    const keyPressedHandler = (e) => {
        if (e.key === 'c' || e.key === 'C' || e.key === 'с' || e.key === 'С') {
            setKeyPressed(true);
        } else {
            setKeyPressed(false);
        }
    };

    useEventListener('keydown', keyPressedHandler);
    useEventListener('keyup', (e) => setKeyPressed(false));

    const [, drop] = useDrop(() => ({
        accept: DraggableTypes.EVENT,
        drop: (item, monitor) => {
            if (!monitor.isOver({ shallow: false })) {
                return;
            }//If hover over event, not a day container
            return { date: props.date, copy: keyPressed }
        }
    }), [props.date, keyPressed, events]);

    const add = () => {
        dispatch(openModal({
            date: props.date,
            updateID: null,
            isNew: true
        }))
    };


    const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    return (
        <div className="day" ref={drop}>
            <div className={classNames({
                'day-header': true,
                'day-header--today': isCurrentDay(props.date),
            })}>
                <span className="day-header-weekday">{capitalize(dayjs(props.date).locale('ru').format('dddd'))}</span>
                <span className="day-header-date"> {dayjs(props.date).locale('ru').format('D MMMM')}</span>
            </div>
            <div className="day-content">
                {events.map((eventId, index) => {
                    return (
                        <EventItemRounded key={index} eventId={eventId} date={props.date} />
                    )
                })}
                {events.length === 0 && (
                    <div className="empty">
                        Нет событий
                    </div>
                )}
            </div>
            <div className="day-add-button" onClick={add}>
                <i className="icon-plus" /> Добавить запись
            </div>
        </div>
    )
}

export { DayAsList };