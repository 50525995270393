import {createSlice} from "@reduxjs/toolkit";

export const iconSlice = createSlice({
    name: 'icons',
    initialState: {
        icons: [],
    },
    reducers: {
        fetchedIcons: (state, action) => {
            state.icons = action.payload;
        }
    },
})

export const {
    fetchedIcons
} = iconSlice.actions;

export default iconSlice.reducer;