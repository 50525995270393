import dayjs from "dayjs";
import classNames from "classnames";
import React, { Fragment, useState } from 'react';
import { useSelector } from "react-redux";

import { OptionSelect } from "../forms/OptionSelect";
import { getMonths, getYearList, hasLeapYear } from '../../functions/serviceFunctions';

import 'dayjs/locale/ru';
import './styles.scss';

function ComparisonDay(props) {
    const events = useSelector(state => state.events.events).filter(event => {
        return dayjs(event.date).isSame(props.day, 'day');
    });

    return <td className={classNames({
        'comparison-table-cell': true,
        'has-events': events.length > 0
    })}>{dayjs(props.day).format('D')}</td>;
}

function Comparison() {
    const yearList = getYearList().map(year => {
        return {
            value: year,
            label: year
        }
    });
    const [selectedYear, setSelectedYear] = useState(dayjs().year());
    const [years, setYears] = useState([]);

    const longFebruary = hasLeapYear(years);

    return (
        <div className="comparison-container">
            <div className="comparison-header">
                <div className="comparison-header-title">Добавить год:</div>
                <div className="comparison-header-years">
                    <OptionSelect selected={{ label: selectedYear, value: selectedYear }} setValue={setSelectedYear} options={yearList} />
                </div>
                <div className="comparison-header-footer">
                    <button className="comparison-header-button" onClick={() => {
                        setYears([...years, selectedYear]);
                    }}>Добавить</button>
                </div>
            </div>
            {years.length > 0 &&
                <table className="comparison-table" style={{ tableLayout: 'fixed' }}>
                    <tr className="comparison-table-header-row">
                        <th>Год</th>
                        {getMonths(years[0]).map((month, index) => (
                            <th key={index}
                                colSpan={addLastDayOfFebruary(month.date, longFebruary) ? month.days.length + 1 : month.days.length}>{month.name}</th>
                        ))}
                    </tr>
                    {years.map((year, index) => (
                        <tr className="comparison-table-row" key={index}>
                            <td className="comparison-table-cell-year">{year}</td>
                            {getMonths(year).map((month, index) => (
                                <Fragment>
                                    {month.days.map((day, index) => (
                                        <ComparisonDay key={index} day={day} />
                                    ))}
                                    {addLastDayOfFebruary(month.date, longFebruary) ?
                                        <td className="comparison-table-cell" key={index + 1}>&nbsp;</td> : null}
                                </Fragment>
                            ))}
                        </tr>
                    ))}
                </table>}
        </div>
    );
}




function addLastDayOfFebruary(date, longFebruary) {
    return dayjs(date).month() === 1 && longFebruary && dayjs(date).daysInMonth() === 28;
}

export { Comparison };