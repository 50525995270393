import { React, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from 'formik';
import { Link, useNavigate } from "react-router-dom";

import { sendRegisterCredentialsAPI } from "../../api";
import { sendRegisterCode } from '../../api/authAPI';

import "../../styles/registration.css";

function Registration() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [isCodeSent, setIsCodeSent] = useState(false);

    const RegistrationCodeSchema = Yup.object().shape({
        email: Yup.string()
            .required('Введите email')
            .email('Некорректный email'),
    })

    const RegistrationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Введите email')
            .email('Некорректный email'),
        password: Yup.string()
            .required('Введите пароль'),
        passwordConfirm: Yup.string()
            .required('Введите подтверждение пароля')
            .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
        code: Yup.string()
            .required('Введите код')
    })

    const registerCode = (email) => {
        sendRegisterCode(email)
            .then(res => {
                if (res.status === 200) {
                    setEmail(email);
                    setIsCodeSent(true);
                }
            }).catch(err => {
                alert(err.response.data.details)
            })
    }


    const register = (email, password, code) => {
        sendRegisterCredentialsAPI(email, password, code)
            .then(res => {
                if (res.status === 200) {
                    navigate('/login')
                }
            }).catch(err => {
                alert(err.response.data.details)
            })
    }

    return (
        <div className="registration-container">
            {!isCodeSent && <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={RegistrationCodeSchema}
                onSubmit={values => {
                    registerCode(values.email);
                }}>
                {({ touched, errors }) => (
                    <Form className="registration-form-container">
                        <div className="registration-form-header">
                            <h1>Регистрация</h1>
                        </div>
                        <div className="registration-form-body">
                            <div className="registration-form-input">
                                <Field type="text" placeholder="&#xE938; Почта" className="email" name="email" />
                                {errors.email && touched.email ? (<div className="registration-form-input-error">
                                    {errors.email}</div>) : null}
                            </div>
                        </div>
                        <div className="registration-form-footer">
                            <button className="registration-form-button" type="submit">Отправить код</button>
                        </div>
                        <div className="login-form-footer">
                            <Link to="/login" className="login-form-link">{t("registration.loginLink")}</Link>
                        </div>
                        <div className="login-form-footer">
                            <Link to="/restore" className="login-form-link">{t("registration.restoreLink")}</Link>
                        </div>
                    </Form>
                )}
            </Formik>}


            {isCodeSent && <Formik
                initialValues={{
                    email: email,
                    password: '',
                    passwordConfirm: '',
                    code: '',
                }}
                validationSchema={RegistrationSchema}
                onSubmit={(values) => {
                    register(values.email, values.password, values.code)
                }}>
                {({ errors, touched }) => (
                    <Form className="registration-form-container">
                        <div className="registration-form-header">
                            <h1>Регистрация</h1>
                        </div>
                        <div className="registration-form-body">
                            <div className="registration-form-input">
                                <Field type="password" placeholder="&#xE937; Придумайте пароль" className="password"
                                    name="password" />
                                {errors.password && touched.password ? (<div className="registration-form-input-error">
                                    {errors.password}</div>) : null}
                            </div>
                            <div className="registration-form-input">
                                <Field type="password" placeholder="&#xE926; Повторите пароль"
                                    className="repeat-password"
                                    name="passwordConfirm" />
                                {errors.passwordConfirm && touched.passwordConfirm ? (
                                    <div className="registration-form-input-error">
                                        {errors.passwordConfirm}</div>) : null}
                            </div>
                            <div className='registration-form-input'>
                                <Field type="number" placeholder="&#xE938; Код" className="code" name="code" />
                                {errors.code && touched.code ? (<div className="registration-form-input-error">
                                    {errors.code}</div>) : null}
                            </div>
                        </div>
                        <div className="registration-form-footer">
                            <button className="registration-form-button" type="submit">Зарегистрироваться</button>
                        </div>
                        <div className="login-form-footer">
                            <Link to="/login" className="login-form-link">{t("registration.loginLink")}</Link>
                        </div>
                        <div className="login-form-footer">
                            <Link to="/restore" className="login-form-link">{t("registration.restoreLink")}</Link>
                        </div>
                    </Form>
                )}
            </Formik>}
        </div>
    );
}

export default Registration;