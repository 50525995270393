import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import { useDrop } from "react-dnd";
import { useSelector, useDispatch, connectAdvanced } from "react-redux";
import dayjs from "dayjs";

import { isCurrentDay, isPast } from "../index";
import { DraggableTypes } from "../../utility/DraggableTypes";
import { AlternateIconEvent } from "../../utility/AlternateIconEvent";
import { openModal } from "../../../store/modal";
import { isCurrentWeekAndYear, isFirstDayOfTheCurrentWeek, isLastDayOfTheCurrentWeek } from "../index";
import "./DayAsTile.scss";
import { copyEventAPI, getEventsAPI, moveEventAPI, updateEventAPI } from "../../../api";
import { addEvent, bufferType, moveEvent, clearBuffer, getEventById, getEventToPaste, getEventsForDate, getEventsPositionsForDate, pasteEvent, setRowForEventPosition, updateEvent } from "../../../store/events";
import { EventItem } from "../Event/EventItem";
import { setHighlightedThematic } from "../../../store/thematics";
import GridAsTile from "../GridLayout/GridAsTile";

DayAsTile.propTypes = {
    onClick: PropTypes.func,
    period: PropTypes.any,
    day: PropTypes.any,
    events: PropTypes.any,
    isSameDate: PropTypes.func,
    event: PropTypes.func,
    isLoweredOpacity: PropTypes.bool,
};

function DayAsTile(props) {
    const dispatch = useDispatch();
    const ref = useRef();
    const date = dayjs(props.day.date).format('YYYY-MM-DD');
    const eventsForDate = useSelector(state => getEventsForDate(state, date));
    const holidays = ["1-1", "2-1", "8-3", "21-3", "22-3", "23-3", "1-5", "7-5", "9-5", "6-7", "1-12", "16-12"];
    const thematics = useSelector(state => state.thematics.thematics);
    const highlightedThematics = useSelector(state => state.thematics.highlightedThematics);
    const showPastDays = useSelector(state => state.settings.showPastDays);
    const design = useSelector(state => state.settings.design);
    const buffer = useSelector(state => state.events.buffer);
    const eventToPaste = useSelector(state => getEventById(state, buffer.id));
    const tileHeight = ref.current?.offsetHeight;
    const tileWidth = ref.current?.offsetWidth;

    const calculateTitleHeight = () => {
        if (design === 'Original') return tileHeight;
        if (design === 'Skeuomorphism') {
            switch (props.period) {
                case 1:
                    return tileHeight - 2.5;
                case 3:
                    return tileHeight - 2.5;
                case 6:
                    return tileHeight - 2.5;
                case 7:
                    return tileHeight - 5;
                case 12:
                    return tileHeight - 2;


            }
        }
    }
    const [extraEvents, setExtraEvents] = useState(0);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const alternate = props.alternate;
    const table = props.table;
    let events = eventsForDate;

    const overlapDiv = useRef()
    const [{ isOver, canDrop, hover }, drop] = useDrop({
        accept: DraggableTypes.EVENT,
        collect: (monitor) => ({
            isOver: !!monitor.isOver({ shallow: true }),
            canDrop: !!monitor.canDrop(),
        }),
        drop: (item, monitor) => {
            if (!dayjs(item.date).isSame(date, 'day')) {
                const event = { ...item, date: props.day.date };
                updateEventAPI(event).then((res) => {
                    const event = res.data;
                    event.date = dayjs(event.date, 'YYYY-MM-DDTHH:mm:ssZ');
                    dispatch(updateEvent(event));
                }).catch((e) => {
                    console.log(e);
                })
            }
        },
        canDrop: (item, monitor) => {
            return !dayjs(item.date).isSame(date, 'day')
        },


    }, [events]);
    if (alternate) {
        events = eventsForDate.slice(0, 8);
    }

    const thematicsForDay = thematics.filter(
        thematic =>
            (dayjs(thematic.date_from).isBefore(props.day.date) &&
                dayjs(thematic.date_to).isAfter(props.day.date)) ||
            dayjs(thematic.date_from).isSame(props.day.date) ||
            dayjs(thematic.date_to).isSame(props.day.date)
    );

    var isBetween = require('dayjs/plugin/isBetween')
    dayjs.extend(isBetween)

    useEffect(() => {
        if (!isMouseOver) return;
        if (props.pasteKeyPressed && eventToPaste != null) {
            if (buffer.type == bufferType.COPY) {
                copyEventAPI(eventToPaste, props.day.date).then((res) => {
                    const event = res.data;
                    event.date = dayjs(event.date, 'YYYY-MM-DDTHH:mm:ssZ');
                    dispatch(addEvent(event));
                }).catch((e) => {
                    console.log(e);
                })
            } else if (buffer.type == bufferType.CUT) {
                const event = { ...eventToPaste, date: props.day.date };
                updateEventAPI(event).then((res) => {
                    const event = res.data;
                    event.date = dayjs(event.date, 'YYYY-MM-DDTHH:mm:ssZ');
                    dispatch(updateEvent(event));
                    dispatch(clearBuffer())
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }, [props.pasteKeyPressed])

    useEffect(() => {
        if (props.escapeKeyPressed) {
            dispatch(clearBuffer())
        }
    })

    useEffect(() => {
        if (eventsForDate.length == 0) {
            setExtraEvents(0);
        }
    }, [eventsForDate])

    const setSelectedDate = (date) => {
        dispatch(openModal({
            date: date,
            updateID: null,
            isNew: false,
        }));
    };

    const setThematic = () => {
        if (thematicsForDay.length > 0) {
            dispatch(setHighlightedThematic(thematicsForDay[0]))
            return
        } else {
            dispatch(setHighlightedThematic(null))
            return
        }
    }

    const onMouseLeave = (e) => {
        setIsMouseOver(false);

        dispatch(setHighlightedThematic(null))
        if (events.length === 0 || ref === null || ref.current === null) return;
        const container = ref.current;
        container.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const onMouseEnter = (e) => {
        setIsMouseOver(true);
    }

    return <td
        ref={el => { ref.current = el; }}
        onClick={props.onClick}

        onMouseEnter={onMouseEnter}
        onMouseOver={e => { e.preventDefault(); setThematic() }}
        onMouseLeave={e => { e.preventDefault(); onMouseLeave() }}
        className={classNames(
            {
                "day-of-month": props.period < 3,
                "day-of-month--current-day": isCurrentDay(props.day.date),
                "day-of-month--past-day": isPast(props.day.date) && showPastDays,
                "day-of-month--not-this-month": !props.day.isCurrentPeriod,
                "period-is-quarter-year-day": props.period === 3,
                "period-is-third-of-year-day": props.period === 4,
                "period-is-half-year-day": props.period === 6,
                "period-is-week": props.period === 7,
                "period-is-year-day": props.period === 12,
                "calendar-day-container": true,
                'alternate': alternate,
                "alternate-current-day": isCurrentDay(props.day.date) && alternate,
                "alternate-past-day": isPast(props.day.date) && alternate && showPastDays,
                "table-day": table,
                "table-current-day": isCurrentDay(props.day.date) && table,
                "table-past-day": isPast(props.day.date) && table && showPastDays,
                "monday": props.day.date.day() === 1,
                "tuesday": props.day.date.day() === 2,
                "wednesday": props.day.date.day() === 3,
                "thursday": props.day.date.day() === 4,
                "friday": props.day.date.day() === 5,
                "saturday": props.day.date.day() === 6,
                "sunday": props.day.date.day() === 0,
                "holiday": holidays.includes(`${props.day.date.date()}-${+props.day.date.month() + 1}`),
                "thematic-cell": thematicsForDay.length,
                'thematic-selection-td': props.isDateSelection,
                'low-opacity': props.isLoweredOpacity,
                // 'current-weeks-day': isCurrentWeekAndYear(props.day.date),
                // 'first-day-of-the-current-week': isFirstDayOfTheCurrentWeek(props.day.date),
                // 'last-day-of-the-current-week': isLastDayOfTheCurrentWeek(props.day.date)


            }
        )}
    >
        {
            dayjs(highlightedThematics?.date_from).isSame(dayjs(props.day.date)) && thematicsForDay[0]

            && (
                <div className="thematic-highlight" >
                    <span style={{ color: 'black', maxWidth: `${tileWidth}px` }}
                        dangerouslySetInnerHTML={{ __html: highlightedThematics?.name }}
                    ></span>

                </div>
            )
        }
        {
            (highlightedThematics
                && thematicsForDay[0]
                && dayjs(thematicsForDay[0].date_from).isSame(dayjs(props.day.date))
                && highlightedThematics.name == thematicsForDay[0].name
                && highlightedThematics.color == thematicsForDay[0].color)
            && (
                <div className="thematic-highlight" >
                    <span style={{ color: 'black', maxWidth: `${tileWidth}px` }}
                        dangerouslySetInnerHTML={{ __html: highlightedThematics?.name }}
                    ></span>

                </div>
            )
        }

        <span className='day-icon calendar-day-container__number' onClick={() => setSelectedDate(props.day.date)}>{props.day.dayOfMonth}</span>
        {
            extraEvents > 0 && <span className="calenday-day-container__extra" dangerouslySetInnerHTML={{ __html: design == 'Skeuomorphism' ? events.length : `+${extraEvents}` }}></span>
        }

        <div
            ref={drop}
            style={{
                width: '100%', height: '100%',
                border: isOver && canDrop ? '2px dashed green' : ''

            }}
        >
            {thematicsForDay.map(thematic => <div className="thematic" key={thematic.id} style={{
                backgroundColor: thematic.color
            }}>
            </div>)}

            {eventsForDate.length > 0 &&
                <GridAsTile
                    events={events}
                    date={date}
                    tileHeight={calculateTitleHeight()}
                    cutKeyPressed={props.cutKeyPressed}
                    copyKeyPressed={props.copyKeyPressed}
                    dragKeyPressed={props.dragKeyPressed}
                    setExtraEvents={setExtraEvents}
                    maxRows={props.maxRows}
                />
            }
        </div>
    </td >;
}

export { DayAsTile };