import { http } from "./api";
import dayjs from "dayjs";

export const addEventAPI = (event) => {
  return http.post('/events',
    {
      "title": event.title,
      "description": event.description,
      "date": event.date.toISOString(),
      "icon": event.icon,
      "color": event.color,
      "rating": event.rating,
      "width": event.width,
      "height": event.height,
      "category": event.category,
      "order": event.order,
      "pos_x": event.pos_x,
      "pos_y": event.pos_y,
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
};

export const updateEventAPI = (event) => {
  return http.put(`/events/${event.id}`,
    {
      "title": event.title,
      "description": event.description,
      "date": event.date.toISOString(),
      "icon": event.icon,
      "color": event.color,
      "rating": event.rating,
      "width": event.width,
      "height": event.height,
      "category": event.category,
      "pos_x": event.pos_x,
      "pos_y": event.pos_y,
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
};

export const moveEventAPI = (id, date, order) => {
  return http.patch(`/events/${id}`,
    {
      "date": date.toISOString(),
      "order": +order
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const orderEventAPI = (id, order) => {
  return http.patch(`/events/${id}`,
    {
      "order": +order
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const swapEventsOrder = (event1, event2) => {
  return http.put(`/events`, [
    {
      "id": event1.id,
      "order": event2.order
    },
    {
      "id": event2.id,
      "order": event1.order
    }
  ],
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const copyEventAPI = (event, date) => {
  const e = { ...event };
  e.date = date;

  return addEventAPI(e);
}

export const deleteEventAPI = (id) => {
  return http.delete(`/events/${id}`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
};

export const deleteEventsAPI = (ids) => {
  return http.delete(`/events`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('token')}`
    },
    data: ids
  });
}

export const getEventsAPI = () => {
  return http.get('/events',
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  ).then((res) => {
    const validKeys = ['id', 'title', 'description', 'date', 'color', 'icon', 'rating', 'width', 'height', 'category', 'order', 'pos_x', 'pos_y'];
    return res.data.map(event => {
      Object.keys(event).forEach((key) => validKeys.includes(key) || delete event[key]);
      // 2023-11-30T18:00:00Z
      event.date = dayjs(event.date, 'YYYY-MM-DDTHH:mm:ssZ');
      return event;
    })

  });
};

export const updateEventsPositionsAPI = (events) => {
  return http.post('/events/update_events_positions', events,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
}