import { ReactComponent as NoEventsImg } from '../../../styles/images/no-events.svg';

import "./styles.scss";

function NoEvents(props) {
    
    return (
        <>
        <div className='no-events'>
            <NoEventsImg/>
            <p>Сохраняйте впечатления, планируйте грандиозные дела.</p>
            <p>Ваши записи появятся здесь.</p>
        </div>

        <div className="no-events-btns">
            <button className="btn btn-green day-layout-btn" onClick={() => props.toggleForm(true)}>Добавить запись</button>
        </div>
        </>
    )
}

export { NoEvents };