import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Registration from "./components/auth/Registration";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import RestorePassword from "./components/auth/RestorePassword";
import ActivateAccount from "./components/auth/ActivateAccount";
import { Comparison } from "./components/Comparison";
import { Modal } from "./components/forms/Modal";
import { CalendarApp } from "./components/calendar/App/CalendarApp";
import { ThematicsList } from "./components/calendar/Thematics";
import Grid from "./components/calendar/GridLayout/Grid";

function App() {
    const auth = useSelector(state => state.auth);
    return (
        <div className="App">
            <Modal />
            <Routes>
                <Route path="/" element={auth.isAuthenticated ? <CalendarApp /> : <Navigate to={'/login'} />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/login" element={<Login />} />
                <Route path="/restore" element={<RestorePassword />} />
                <Route path="/activate" element={<ActivateAccount />} />
                <Route path="/comparison" element={<Comparison />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/thematics" element={auth.isAuthenticated ? <ThematicsList /> : <Navigate to={'/login'} />} />
                <Route path="/test" element={<Grid />} />
            </Routes>
        </div>
    );
}

export default App;
