import classNames from 'classnames';
import { useState, useRef } from "react";
import OutsideClick from '../../../functions/OutsideClick';

import './styles.scss'

function OptionSelect(props) {
    const ref = useRef(null);
    OutsideClick(ref, () => {
        setIsOpen(false);
    })

    const [isOpen, setIsOpen] = useState(false);

    const toggling = () => setIsOpen(!isOpen);
    const onOptionClicked = (value) => {
        props.setValue(value.value);

        setIsOpen(false);
    }


    return (
        <div className={classNames('dropdown-container', props.type)} ref={ref} >
            <div
                className="dropdown-header"

                onMouseDown={(e) => { e.preventDefault(); toggling(); }}
            >
                {props.selected ? props.selected.label : props.options[0].label}
            </div>
            {
                isOpen && (
                    <div className="dropdown-list-container" >
                        <ul className="dropdown-list">
                            {props.options.map(option => (
                                <li className="dropdown-list-item" key={option.value} onMouseDown={(e) => { e.stopPropagation(); e.preventDefault(); onOptionClicked(option); }}>
                                    {option.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        </div >
    )
}

export { OptionSelect };