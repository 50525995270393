import classNames from "classnames";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import * as PropTypes from "prop-types";

import { getEventById, setOrder, swapEventsOrders, updateEvent } from "../../../store/events";
import { orderEventAPI, swapEventsOrder, updateEventAPI } from "../../../api/eventAPI";
import { DraggableTypes } from "../../utility/DraggableTypes";
import { EventIcon } from "../../utility/EventIcon";
import { setEventsToDelete } from "../../../store/settings";
import 'dayjs/locale/ru';
import "./EventDetail.scss";
import dayjs from "dayjs";
import Grid from "../GridLayout/Grid";


function EventDetail({ id, edit }) {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [isDetailed, setIsDetailed] = useState(false);

    const event = useSelector(state => getEventById(state, id));
    const filter = useSelector(state => state.settings.filter);
    const icon = EventIcon({ iconName: event.icon });
    const eventDeletion = useSelector(state => state.settings.eventDeletion);
    const eventsToDelete = useSelector(state => state.settings.eventsToDelete);
    const [isEventCheckedForDeletion, setEventCheckedForDeletion] = useState(eventsToDelete.includes(event.id));

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.addEventListener("wheel", scrollEvent)
            return function cleanup() {
                const copyEventsRef = { ...ref };
                if (copyEventsRef && copyEventsRef.current)
                    copyEventsRef.current.removeEventListener("wheel", scrollEvent)
            }
        }
    });

    if (filter.categories.length > 0 && !filter.categories.includes(event.category)) return (<></>);

    const scrollEvent = (e) => {
        e.stopPropagation();
    }

    const handleBtn = (id) => {
        edit(id);
    }


    const showButton = () => {
        setIsDetailed(true)
    }
    const hideButton = () => {
        setIsDetailed(false);
    }

    const getDescription = () => {
        let description = event.description;
        const pos = event.title.search(/#[0-9a-zA-Z]{6}/);
        if (pos !== -1) {
            const color = event.title.substring(pos, pos + 7);
            description = `<font color="${color}">${event.description}</font>`;
        }

        description = description.replace(/\n/g, "<br/>");

        return description;
    }
    const setEventToDelete = (e, value) => {
        e.stopPropagation();
        dispatch(setEventsToDelete(value))
        setEventCheckedForDeletion(e.target.checked)
    }

    return <div ref={ref}
        className={classNames({
            "day-layout-event": true,
            "day-layout-event_detail": isDetailed && (event.description.length !== 0),
            "day-layout-event_no-description": event.description?.length === 0,
        })}
        onMouseOver={(e) => showButton()}
        onMouseOut={(e) => hideButton()}
        style={{ backgroundColor: event.color, order: event.order, width: '100%', height: '100%' }}
    >
        <div
            className="day-layout-event-short"

            style={{ backgroundColor: event.color }}
        >
            <p className="title" dangerouslySetInnerHTML={{ __html: event.title }} />
            {icon.src &&
                <img className="event-icon" src={icon.src} alt="event icon" />}
            <i className="icon-triangle" />
            {eventDeletion &&
                <input type='checkbox' className="day-layout-checkbox" checked={isEventCheckedForDeletion} onClick={(e) => { setEventToDelete(e, event.id) }} />
            }
        </div>
        {
            event.description && <div
                className="day-layout-event-detail"

                style={{ backgroundColor: event.color }}
            >

                <p className="title" dangerouslySetInnerHTML={{ __html: event.title }} />
                <p className="desc" dangerouslySetInnerHTML={{ __html: getDescription() }} />
                <i className="icon-triangle" />
            </div>
        }

        <div className={classNames({
            "day-layout-event__btn": true,
            "day-layout-event__btn_active": isDetailed,
        })}>
            <button className="btn" style={{ backgroundColor: event.color }} onClick={(e) => { handleBtn(event.id); }}><i className="icon-edit" /></button>
        </div>

    </div >;
}

EventDetail.propTypes = {
    event: PropTypes.any,
    onClick: PropTypes.func,
    onClick1: PropTypes.func
};

export { EventDetail };    