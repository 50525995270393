import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OptionSelect } from '../OptionSelect';
import IconClose from '../../../icons/icon-close.svg';
import classNames from 'classnames';

import IconRectangle from '../../../icons/icon-rectangle.svg'
import './styles.scss';
import { use } from 'i18next';
import { setDateSelection } from '../../../store/modal';
import { addThematics as addThematicsAPI, deleteThematic as deleteThematicAPI, updateThematic as updateThematicsAPI } from '../../../api/thematics';
import dayjs from 'dayjs';
import ArrowSelect from '../../../icons/icon-arrow-select.svg'
import { addThematic, updateThematic, deleteThematic as deleteThematicStore } from '../../../store/thematics';
import OutsideClick from '../../../functions/OutsideClick';
function Thematics({ thematic, close, date }) {
    const colors = useSelector(state => state.colors.colors);
    const isDateSelection = useSelector(state => state.modal.isDateSelection);
    const thematics = useSelector(state => state.thematics.thematics)
    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [existingThematics, setExistingThematics] = useState([])
    const [rearrangedThematics, setRearrangedThematics] = useState([])
    const [hasExistingThematic, setHasExistingThematic] = useState(false);
    const [overlapsOldFromRight, setOverlapsOldFromRight] = useState(null)
    const [overlapsOldFromLeft, setOverlapsOldFromLeft] = useState(null)
    const [overlapsOldEntirely, setOverlapsOldEntirely] = useState(null)
    const [insideOfOld, setInsideOfOld] = useState(null)

    const [error, setError] = useState('')
    const colorIndicator = useRef()
    const ref = useRef()
    useEffect(() => {
        if (thematic != null) {
            setName(thematic.name)
            setColor(thematic.color)
            setDateFrom(dayjs(thematic.date_from).format('YYYY-MM-DD'));
            setDateTo(dayjs(thematic.date_to).format('YYYY-MM-DD'));
        } else if (date != null) {
            setDateFrom(dayjs(date).format('YYYY-MM-DD'));
            setDateTo(dayjs(date).add(1, 'day').format('YYYY-MM-DD'));
        }
    }, [thematic, date]);

    const dispatch = useDispatch();
    OutsideClick(ref, () => {
        if (close == null) return;
        close()
    })
    function handleName(e) {
        if (e.target.value.length > 40) return
        setName(e.target.value);
    }

    function handleDateFrom(e) {
        setDateFrom(e.target.value);
    }


    function handleDateTo(e) {
        setDateTo(e.target.value);
    }

    function handleDelete() {
        if (confirmThematicDeletion) {
            deleteThematic()
        }
        else setConfirmThematicDeletion(true)
    }

    function rearrangeThematics() {

        existingThematics.forEach((thematic) => {

            if (dayjs(thematic.date_from).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]')
                && dayjs(thematic.date_to).isBetween(dayjs(dateFrom), dayjs(dateTo))) {

                deleteThematicAPI(thematic.id).then(response => {
                    if (response.status === 204) {
                        dispatch(deleteThematicStore(thematic.id));
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
            if (dayjs(thematic.date_from).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]')
                && dayjs(thematic.date_to).isAfter(dayjs(dateTo))) {

                updateThematicsAPI({
                    name: thematic.name,
                    color: thematic.color,
                    date_from: dayjs(dateTo).add(1, 'day'),
                    date_to: thematic.date_to,
                    id: thematic.id
                }).then(response => {
                    if (response.status === 200) {
                        dispatch(updateThematic(response.data));
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
            if (dayjs(thematic.date_to).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]')
                && dayjs(thematic.date_from).isBefore(dayjs(dateFrom))) {

                updateThematicsAPI({
                    name: thematic.name,
                    color: thematic.color,
                    date_from: thematic.date_from,
                    date_to: dayjs(dateFrom).subtract(1, 'day'),
                    id: thematic.id
                }).then(response => {
                    if (response.status === 200) {
                        dispatch(updateThematic(response.data));
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
            if (dayjs(thematic.date_from).isBefore(dayjs(dateFrom))
                && dayjs(thematic.date_to).isAfter(dayjs(dateTo))) {

                let deletedThematic = thematic;
                deleteThematicAPI(thematic.id).then(response => {
                    if (response.status === 204) {
                        dispatch(deleteThematicStore(thematic.id));
                        addThematicsAPI({
                            name: deletedThematic.name,
                            color: deletedThematic.color,
                            date_from: deletedThematic.date_from,
                            date_to: dayjs(dateFrom).subtract(1, 'day')
                        }).then(response => {
                            if (response.status === 200) {
                                dispatch(addThematic(response.data));
                                addThematicsAPI({
                                    name: deletedThematic.name,
                                    color: deletedThematic.color,
                                    date_from: dayjs(dateTo).add(1, 'day'),
                                    date_to: deletedThematic.date_to
                                }).then(response => {
                                    if (response.status === 200) {
                                        dispatch(addThematic(response.data));
                                    }
                                }).catch(error => {
                                    console.log(error);
                                })
                            }
                        }).catch(error => {
                            console.log(error);
                        })
                    }
                }).catch(error => {
                    console.log(error);
                })

            }
            if (dayjs(thematic.date_from).isSame(dayjs(dateFrom)) && dayjs(thematic.date_to).isSame(dayjs(dateTo))) {
                deleteThematicAPI(thematic.id).then(response => {
                    if (response.status === 204) {
                        dispatch(deleteThematicStore(thematic.id));
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
            if (dayjs(thematic.date_from).isSame(dayjs(dateFrom)) && dayjs(thematic.date_to).isBefore(dayjs(dateTo))) {
                deleteThematicAPI(thematic.id).then(response => {
                    if (response.status === 204) {
                        dispatch(deleteThematicStore(thematic.id));
                    }
                }).catch(error => {
                    console.log(error);
                })

            }
            if (dayjs(thematic.date_to).isSame(dayjs(dateTo)) && dayjs(thematic.date_from).isAfter(dayjs(dateFrom))) {
                deleteThematicAPI(thematic.id).then(response => {
                    if (response.status === 204) {
                        dispatch(deleteThematicStore(thematic.id));
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
        })

        if (!thematic) {
            addThematicsAPI({
                name: name,
                color: color,
                date_from: dayjs(dateFrom).toISOString(),
                date_to: dayjs(dateTo).toISOString()
            }).then(response => {
                if (response.status === 200) {
                    dispatch(addThematic(response.data));
                    if (!hasExistingThematic) close()
                }
            }).catch(error => {
                console.log(error);
            })
        }
        else {
            updateThematicsAPI({
                name: name,
                color: color,
                date_from: dayjs(dateFrom).toISOString(),
                date_to: dayjs(dateTo).toISOString(),
                id: thematic.id
            }).then(response => {
                if (response.status === 200) {
                    dispatch(updateThematic(response.data));
                    close()
                }
            }).catch(error => {
                console.log(error);
            })
        }

        close()
    }

    function save() {
        let thematicExist = false
        if (!dateFrom || !dateTo || !color || !name) {
            setError('Ошибка: Пожалуйста, заполните все поля')
            return
        } else {
            setError('')
        }
        let date_from = dayjs(dateFrom).toISOString();
        let date_to = dayjs(dateTo).toISOString();

        thematics.map((thematic) => {
            if (dayjs(thematic.date_from).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]') || dayjs(thematic.date_to).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]')) {
                setHasExistingThematic(true)
                thematicExist = true
                setExistingThematics((prevThematics) => [...prevThematics, thematic]);
            }

            if (dayjs(thematic.date_from).isBefore(dayjs(dateFrom))
                && dayjs(thematic.date_to).isAfter(dayjs(dateTo))) {

                setHasExistingThematic(true)
                thematicExist = true
                setExistingThematics((prevThematics) => [...prevThematics, thematic]);
            }


        })
        if (thematicExist) return

        addThematicsAPI({
            name: name,
            color: color,
            date_from: date_from,
            date_to: date_to
        }).then(response => {
            if (response.status === 200) {
                dispatch(addThematic(response.data));
                if (!hasExistingThematic) close()
            }
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {

        if (existingThematics.length) {
            let existingThematicsCopy = [...existingThematics];
            existingThematicsCopy.forEach((thematic) => {

                if (dayjs(thematic.date_from).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]')
                    && dayjs(thematic.date_to).isBetween(dayjs(dateFrom), dayjs(dateTo))) {
                    existingThematicsCopy = existingThematicsCopy.filter((thema) => thema.id !== thematic.id);

                }

                if (dayjs(thematic.date_from).isSame(dayjs(dateFrom)) && dayjs(thematic.date_to).isBefore(dayjs(dateTo))) {
                    existingThematicsCopy = existingThematicsCopy.filter((thema) => thema.id !== thematic.id);

                }
                if (dayjs(thematic.date_to).isSame(dayjs(dateTo)) && dayjs(thematic.date_from).isAfter(dayjs(dateFrom))) {
                    existingThematicsCopy = existingThematicsCopy.filter((thema) => thema.id !== thematic.id);

                }
                if (dayjs(thematic.date_from).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]')
                    && dayjs(thematic.date_to).isAfter(dayjs(dateTo))) {

                    existingThematicsCopy.push({ name: thematic.name, color: thematic.color, date_from: dayjs(dateTo).add(1, 'day'), date_to: thematic.date_to })
                    existingThematicsCopy = existingThematicsCopy.filter((thema) => thema.id !== thematic.id);
                }
                if (dayjs(thematic.date_to).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]')
                    && dayjs(thematic.date_from).isBefore(dayjs(dateFrom))) {

                    existingThematicsCopy.push({ name: thematic.name, color: thematic.color, date_from: thematic.date_from, date_to: dayjs(dateFrom).subtract(1, 'day') })
                    existingThematicsCopy = existingThematicsCopy.filter((thema) => thema.id !== thematic.id);

                }
                if (dayjs(thematic.date_from).isBefore(dayjs(dateFrom))
                    && dayjs(thematic.date_to).isAfter(dayjs(dateTo))) {

                    existingThematicsCopy.push({ name: thematic.name, color: thematic.color, date_from: thematic.date_from, date_to: dayjs(dateFrom).subtract(1, 'day') })
                    existingThematicsCopy.push({ name: thematic.name, color: thematic.color, date_from: dayjs(dateTo).add(1, 'day'), date_to: thematic.date_to })
                    existingThematicsCopy = existingThematicsCopy.filter((thema) => thema.id !== thematic.id);

                }

                if (dayjs(thematic.date_from).isSame(dayjs(dateFrom)) && dayjs(thematic.date_to).isSame(dayjs(dateTo))) {

                    existingThematicsCopy = existingThematicsCopy.filter((thema) => thema.id !== thematic.id);
                }

            })
            existingThematicsCopy.push({ name: name, color: color, date_from: dateFrom, date_to: dateTo });

            setRearrangedThematics(existingThematicsCopy);
        }
    }, [existingThematics])
    existingThematics.sort((a, b) => dayjs(a.date_from) - dayjs(b.date_from))
    rearrangedThematics.sort((a, b) => dayjs(a.date_from) - dayjs(b.date_from))

    const [confirmThematicDeletion, setConfirmThematicDeletion] = useState(false)


    const toggleDateSelection = () => {
        dispatch(setDateSelection(true))
        if (close == null) return;
        close()
    }
    const deleteThematic = () => {
        deleteThematicAPI(thematic.id).then(response => {
            if (response.status === 204) {
                dispatch(deleteThematicStore(thematic.id));
                close()
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const update = (value) => {
        let thematicExist = false

        if (!dateFrom || !dateTo || !color || !name || !thematic.id) {
            setError('Ошибка: Пожалуйста, заполните все поля')

            return
        }
        else {
            setError('')

        }
        let date_from = dayjs(dateFrom).toISOString();
        let date_to = dayjs(dateTo).toISOString();
        thematics.map((thematic) => {
            if (dayjs(thematic.date_from).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]') || dayjs(thematic.date_to).isBetween(dayjs(dateFrom), dayjs(dateTo), 'day', '[]')) {
                if (thematic.id != value.id) {
                    setHasExistingThematic(true)
                    thematicExist = true
                    setExistingThematics((prevThematics) => [...prevThematics, thematic]);
                }


            }

            if (dayjs(thematic.date_from).isBefore(dayjs(dateFrom))
                && dayjs(thematic.date_to).isAfter(dayjs(dateTo))) {
                if (thematic.id != value.id) {
                    setHasExistingThematic(true)
                    thematicExist = true
                    setExistingThematics((prevThematics) => [...prevThematics, thematic]);
                }

            }

        })
        if (thematicExist) return



        updateThematicsAPI({
            name: name,
            color: color,
            date_from: date_from,
            date_to: date_to,
            id: thematic.id
        }).then(response => {
            if (response.status === 200) {
                dispatch(updateThematic(response.data));
                close()
            }
        }).catch(error => {
            console.log(error);
        })
    }
    return hasExistingThematic ? (
        <div style={{ width: '616px' }} className="thematics-form" ref={ref}>
            <div className="thematics-form-header">
                <h4 style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto', color: '#535757' }}>Заменить тематику</h4>

                <button className="btn close-btn" onClick={() => close()}>
                    <img src={IconClose} alt="close" />
                </button>
            </div>

            <div className='thematics-form-block' style={{ marginBottom: '0px' }}>
                <p style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'Roboto', color: '#535757' }}>
                    Выбранные даты уже имеют тематику.<br />
                    Заменить тематику в этих датах?
                </p>
            </div>
            {hasExistingThematic && <div className='existing-thematics-container'>
                {existingThematics.map((thematic) => (
                    <div key={thematic.id} style={{ backgroundColor: `${thematic.color}80`, border: `1px solid ${thematic.color == '#FFFFFF' ? 'gray' : thematic.color}`, borderRadius: '3px', padding: '8px', width: '100%' }}>
                        <div className='existing-thematic'>
                            <span style={{ color: thematic.color == '#FFFFFF' || thematic.color == '#F5E663' ? 'black' : thematic.color, fontFamily: 'Roboto', fontSize: '16px', fontWeight: '500', marginRight: '6px' }}>{thematic.name}</span>
                            <div className='existing-thematic-dates'>

                                <span style={{ fontFamily: 'Roboto', fontSize: '16px', color: '#535757' }}>({dayjs(thematic.date_from).format('DD.MM.YYYY')}</span>
                                <span style={{ fontFamily: 'Roboto', fontSize: '16px', color: '#535757' }}> - {dayjs(thematic.date_to).format('DD.MM.YYYY')}  )</span>

                            </div>

                        </div>

                        <div />
                    </div>
                ))}
                <img src={ArrowSelect} style={{ width: '16px', height: '16px', margin: '10px' }} />
                {rearrangedThematics.map((thematic) => (
                    <div key={thematic.id} style={{ backgroundColor: `${thematic.color}80`, border: `1px solid ${thematic.color == '#FFFFFF' ? 'gray' : thematic.color}`, borderRadius: '3px', padding: '8px', width: '100%' }}>
                        <div className='existing-thematic'>
                            <span style={{ color: thematic.color == '#FFFFFF' || thematic.color == '#F5E663' ? 'black' : thematic.color, fontFamily: 'Roboto', fontSize: '16px', fontWeight: '500', marginRight: '6px' }}>{thematic.name}</span>
                            <div className='existing-thematic-dates'>

                                <span style={{ fontFamily: 'Roboto', fontSize: '16px', color: '#535757' }}>({dayjs(thematic.date_from).format('DD.MM.YYYY')}</span>
                                <span style={{ fontFamily: 'Roboto', fontSize: '16px', color: '#535757' }}> - {dayjs(thematic.date_to).format('DD.MM.YYYY')}  )</span>

                            </div>

                        </div>

                        <div />
                    </div>
                ))}
            </div>
            }


            <div className='thematics-replace-button-container' style={{ marginTop: `40px` }}>
                <button className="btn btn-green btn-save" style={{ width: '100%' }} onClick={() => rearrangeThematics()}>Заменить и сохранить</button>
                <button className="btn" style={{ width: '100%', border: '1px solid #069685' }} onClick={() => close()}>Отмена</button>
            </div>
        </div>
    ) : (
        <div style={{ width: '616px' }} className="thematics-form" ref={ref}>
            <div className="thematics-form-header">
                <h4 style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto', color: '#535757' }}>{thematic ? "Редактировать тематику" : "Добавить тематику"}</h4>
                <button className="btn close-btn" onClick={() => close()}>
                    <img src={IconClose} alt="close" />
                </button>
            </div>

            <div className='thematics-form-block'>
                <input required type='text' className='thematic-input' style={{ height: `42px`, paddingLeft: '12px', paddingRight: '12px', marginRight: '12px' }} value={name} onChange={handleName} placeholder='Название тематики' />
                <OptionSelect
                    type="font-fill-select"
                    options={colors.map(color => {
                        return {
                            value: color.color,
                            label: <i style={{ color: color.color }} className="icon-color" />
                        };
                    })}
                    setValue={setColor}
                    selected={{
                        label: <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.94">
                                <rect width="24" height="24" rx="5" fill={color == '#FFFFFF' || color == '#F5E663' ? 'gray' : '#FCFCFC'} />
                                <g clip-path="url(#clip0_4904_18914)">
                                    <path d="M5.8125 18.1875H18.1875V19.875H5.8125V18.1875Z" fill={color ? color : '#939393'} />
                                    <path d="M15.1253 14.1176C15.1253 13.2815 16.3752 11.9173 16.3752 11.9173C16.3752 11.9173 17.625 13.2815 17.625 14.1176C17.625 14.8092 17.0626 15.375 16.3752 15.375C15.6878 15.375 15.1253 14.8092 15.1253 14.1176Z" fill={color ? color : '#939393'} />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8504 10.3079L9.26366 4.6875L8.38253 5.57393L9.86983 7.07018L6.65152 10.3079C6.28283 10.6788 6.28283 11.276 6.65152 11.6407L10.0885 15.0984C10.2698 15.2807 10.5135 15.375 10.751 15.375C10.9884 15.375 11.2321 15.2807 11.4134 15.0984L14.8504 11.6407C15.2191 11.276 15.2191 10.6788 14.8504 10.3079ZM10.751 7.9629L7.75762 10.9743H13.7443L10.751 7.9629Z" fill={color ? color : '#939393'} />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_4904_18914">
                                    <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                                </clipPath>
                            </defs>
                        </svg>


                    }}
                />
            </div>
            <span style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'Roboto', color: '#535757' }}>Выберите даты</span>
            <div className='thematics-form-block' style={{ gap: '12px', marginTop: '12px', display: 'flex' }}>
                <input className='date-picker' type="date" value={dateFrom} onChange={handleDateFrom} />
                <img src={IconRectangle} alt='divider' />
                <input className='date-picker' type="date" value={dateTo} onChange={handleDateTo} />
                {/* <button type='button' className="btn" style={{ width: '100%' }} onClick={toggleDateSelection}>Отметить в календаре</button> */}
            </div>

            {thematic && (
                <div className='thematics-edit-button-container'>
                    <button type='button' className="btn btn-green btn-save" onClick={() => { update(thematic) }}>Сохранить</button>
                    <button type='button' className="btn  btn-delete" onClick={deleteThematic}>
                        <i className='icon-bin icon-bin_active' />
                    </button>

                    <button
                        type='button'
                        className={classNames({
                            "btn": true,
                            "btn-delete": true,
                            "btn-red": confirmThematicDeletion,
                        })}
                        onClick={() => handleDelete()}
                    >
                        <i className={classNames({
                            "icon-bin": true,
                            "icon-bin_active": confirmThematicDeletion,
                        })} />
                        {confirmThematicDeletion && "Да, удалить"}
                    </button>
                </div>
            )}

            {!thematic && <button type='button' className="btn btn-green btn-save" onClick={save}>Сохранить</button>}

            {error && <span style={{ color: 'red', marginTop: '20px' }}>{error}</span>}
        </div>
    )
}

export { Thematics };