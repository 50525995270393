import eventIconSmile from "../../../icons/event-icons/event-icon-smile.svg";
import eventIconRun from "../../../icons/event-icons/event-icon-run.svg";
import eventIconCutlery from "../../../icons/event-icons/event-icon-cutlery.svg";
import eventIconMountain from "../../../icons/event-icons/event-icon-mountain.svg";
import eventIconWine from "../../../icons/event-icons/event-icon-wine.svg";
import eventIconDance from "../../../icons/event-icons/event-icon-dance.svg";
import eventIconFlower from "../../../icons/event-icons/event-icon-flower.svg";
import eventIconSquirrel from "../../../icons/event-icons/event-icon-squirrel.svg";
import eventIconLocker from "../../../icons/event-icons/event-icon-locker.svg";
import eventIconPhoto from "../../../icons/event-icons/event-icon-photo.svg";

function EventIcons() {
    const icons = [
        { name: "empty", src: "" },
        { name: "smile", src: eventIconSmile },
        { name: "run", src: eventIconRun },
        { name: "cutlery", src: eventIconCutlery },
        { name: "mountain", src: eventIconMountain },
        { name: "wine", src: eventIconWine },
        { name: "dance", src: eventIconDance },
        { name: "flower", src: eventIconFlower },
        { name: "squirrel", src: eventIconSquirrel },
        { name: "locker", src: eventIconLocker },
        { name: "photo", src: eventIconPhoto },
    ]
    return icons;
}

function EventIcon({ iconName }) {
    const icon = EventIcons().find(icon => { return icon.name === iconName });
    if (icon)
        return icon;

    return EventIcons()[0];
}

export { EventIcon, EventIcons };