import { http } from "./api";

export const getUser = () => {
  return http.get('/users/me',
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
};

export const getProfileDataAPI = (id) => {
  return http.get(`/users/me/profile`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  ).then((res) => res.data);
};

export const setProfileDataAPI = (id, config) => {
  return http.put(`/users/me/profile`,
    {
      "theme": config.theme,
      "past_days": config.past_days,
      "theme_color": config.theme_color,
      "first_month": config.first_month,
      "first_year": config.first_year,
      "is_custom_initial_date": config.is_custom_initial_date,
      "current_view": config.current_view,
      "design": config.design,
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
};

export const setLayoutAPI = (id, layout) => {
  return http.patch(`/users/me/profile`,
    {
      "current_view": +layout
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const setThemeAPI = (id, theme) => {
  return http.patch(`/users/me/profile`,
    {
      "theme": theme
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const setPastDaysAPI = (id, pastDays) => {
  return http.patch(`/users/me/profile`,
    {
      "past_days": pastDays
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const setThemeColorAPI = (id, themeColor) => {
  return http.patch(`/users/me/profile`,
    {
      "theme_color": themeColor
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const setInitialDate = (month, year) => {
  return http.patch(`/users/me/profile`,
    {
      "first_month": month,
      "first_year": year
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const setFirstMonthAPI = (id, firstMonth) => {
  return http.patch(`/users/me/profile`,
    {
      "first_month": firstMonth
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const setFirstYearAPI = (firstYear) => {
  return http.patch(`/users/me/profile`,
    {
      "first_year": firstYear
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const setIsCustomInitialDateAPI = (isCustomInitialDate) => {
  return http.patch(`/users/me/profile`,
    {
      "is_custom_initial_date": isCustomInitialDate
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}

export const setDesignAPI = (designName) => {
  return http.patch(`/users/me/profile`,
    {
      "design": designName
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
}