import { createSlice, current } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const toEventsByIds = (events) => {
    return events.reduce((acc, event) => {
        event.x = event.pos_x;
        event.y = event.pos_y;
        event.w = event.width / 50;
        event.h = event.height / 50;

        acc[event.id] = event;

        return acc;
    }, {})
}

export const toEventsByDates = (events) => {
    return events.reduce((acc, event) => {
        const date = dayjs(event.date).format('YYYY-MM-DD');
        if (!acc[date]) {
            acc[date] = [];
        }

        acc[date].push(event.id);

        return acc;
    }, {})
}

export const getEventsForDate = (state, date) => {
    if (date == null) return [];

    return state.events.eventsByDates[date] ? state.events.eventsByDates[date] : [];
}

export const getEventById = (state, id) => {
    if (id == null) return null;

    return state.events.eventsByIds[id] ? state.events.eventsByIds[id] : null;
}

export const bufferType = {
    COPY: 'COPY',
    CUT: 'CUT',
}

export const eventSlice = createSlice({
    name: 'events',
    initialState: {
        eventsByIds: {},
        eventsByDates: {},
        buffer: {
            id: null,
            type: null,
        },
    },
    reducers: {
        storeEvents: (state, action) => {
            state.eventsByIds = toEventsByIds(action.payload);
            state.eventsByDates = toEventsByDates(action.payload);
        },

        addEvent: (state, action) => {
            const event = action.payload;

            event.x = event.pos_x;
            event.y = event.pos_y;
            event.w = event.width / 50;
            event.h = event.height / 50;

            state.eventsByIds[event.id] = event;
            const date = dayjs(event.date).format('YYYY-MM-DD');
            if (!state.eventsByDates[date]) {
                state.eventsByDates[date] = [];
            }

            state.eventsByDates[date].push(event.id);
        },
        removeEvent: (state, action) => {
            const id = action.payload;
            const event = state.eventsByIds[id];
            if (event) {
                const date = dayjs(event.date).format('YYYY-MM-DD');
                state.eventsByDates[date] = state.eventsByDates[date].filter(eventId => eventId !== id);
                delete state.eventsByIds[action.payload.id];
            }
        },
        removeEvents: (state, action) => {
            const ids = action.payload;

            ids.forEach(id => {
                const event = state.eventsByIds[id];
                if (event) {
                    const date = dayjs(event.date).format('YYYY-MM-DD');
                    state.eventsByDates[date] = state.eventsByDates[date].filter(eventId => eventId !== id);
                    delete state.eventsByIds[id];
                }
            })
        },
        updateEvent: (state, action) => {
            const id = action.payload.id;
            if (!state.eventsByIds[id]) {
                return null;
            }

            const event = { ...state.eventsByIds[id] };

            const newDate = action.payload.date;
            const oldDate = event.date;

            event.title = action.payload.title;
            event.description = action.payload.description;
            event.date = newDate;
            event.color = action.payload.color;
            event.icon = action.payload.icon;
            event.fontSize = action.payload.fontSize;
            event.rating = action.payload.rating;
            event.width = action.payload.width;
            event.height = action.payload.height;
            event.category = action.payload.category;
            event.pos_x = action.payload.pos_x;
            event.pos_y = action.payload.pos_y;

            event.x = action.payload.pos_x;
            event.y = action.payload.pos_y;
            event.w = action.payload.width / 50;
            event.h = action.payload.height / 50;

            state.eventsByIds[id] = event;

            if (oldDate !== newDate) {
                const n = dayjs(newDate).format('YYYY-MM-DD');
                const o = dayjs(oldDate).format('YYYY-MM-DD');
                if (!state.eventsByDates[n]) {
                    state.eventsByDates[n] = [];
                }

                state.eventsByDates[o] = state.eventsByDates[o].filter(eventId => eventId !== id);
                state.eventsByDates[n].push(id);
            }
        },
        copyEvent: (state, action) => {
            const id = action.payload;
            state.buffer.id = id;
            state.buffer.type = bufferType.COPY;
        },
        cutEvent: (state, action) => {
            const id = action.payload;

            state.buffer.id = id;
            state.buffer.type = bufferType.CUT;
        },
        clearBuffer: (state) => {
            state.buffer.id = null;
            state.buffer.type = null;
        },
        pasteEvent: (state, action) => {
            const id = state.buffer.id;
            const type = state.buffer.type;
            const date = action.payload;
            const newDate = dayjs(date).format('YYYY-MM-DD');

            if (type === bufferType.CUT) {
                state.eventsByDates[date] = state.eventsByDates[date].filter(eventId => eventId !== id);
            }
            const event = { ...state.eventsByIds[id] };
            event.date = date;

            state.eventsByIds[id] = event;
            if (!state.eventsByDates[newDate]) {
                state.eventsByDates[newDate] = [];
            }

            state.eventsByDates[newDate].push(id);
            // state.buffer = {
            //     id: null,
            //     type: null,
            // };
        },
        updateEventsPositions: (state, action) => {
            const events = action.payload;
            events.forEach((e) => {
                const event = state.eventsByIds[e.id];
                const date = dayjs(event.date).format('YYYY-MM-DD');
                const ids = state.eventsByDates[date].map((id) => id);

                event.pos_x = e.pos_x;
                event.pos_y = e.pos_y;
                event.width = e.width;
                event.height = e.height;

                state.eventsByIds[e.id] = event;
                state.eventsByDates[date] = ids;
            })
        },


    },
})

export const {
    storeEvents,
    addEvent,
    removeEvent,
    removeEvents,
    updateEvent,
    moveEvent,
    copyEvent,
    cutEvent,
    clearBuffer,
    setOrder,
    swapEventsOrders,
    setRowForEventPosition,
    changeEventPosition,
    updateEventsPositions,
} = eventSlice.actions;

export default eventSlice.reducer;