import "./styles.scss"

function ToggleSwitch({ value, setValue, label }) {
    const handleChange = (e) => {
        setValue(e.target.checked);
    }

    return (
        <>
            <label className="toggle-switch">
                <input type="checkbox" onChange={handleChange} checked={value}  />
                <span className="slider"></span>
            </label>
            <span className="toggle-switch-label">{label}</span>
        </>
    );
}

export default ToggleSwitch;