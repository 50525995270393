import { createSlice } from "@reduxjs/toolkit";


export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        isOpen: false,
        isThematicsOpen: false,
        date: null,
        updateID: null,
        isNew: false,
        thematic: null,
        isDateSelection: false
    },
    reducers: {
        openModal: (state, action) => {
            state.isOpen = true;
            state.isThematicsOpen = false;
            state.date = action.payload.date;
            state.updateID = action.payload.updateID;
            state.isNew = action.payload.isNew;
        },
        closeModal: (state) => {
            state.isOpen = false;
            state.date = null;
            state.updateID = null;
            state.isNew = false;
            state.isThematicsOpen = false;
        },
        openThematicsModal: (state, action) => {
            state.isOpen = true;
            state.isThematicsOpen = true;
            state.date = action.payload?.date ? action.payload.date : null
            state.thematic = action.payload?.thematic ? action.payload.thematic : null
        },
        closeThematicsModal: (state) => {
            state.isOpen = false;
            state.isThematicsOpen = false;
        },
        setDateSelection: (state, action) => {
            state.isDateSelection = action.payload
        }
    },
});

export const { openModal, closeModal, openThematicsModal, closeThematicsModal, setDateSelection } = modalSlice.actions;

export default modalSlice.reducer;