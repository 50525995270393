import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";

import '../../styles/restore.css';
import { restorePassword, sendRestoreCode } from "../../api/authAPI";
import { setRefreshToken, setToken, setUser } from "../../functions/TokenFunctions";
import { login } from "../../store/auth";

export default function RestorePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [isCodeSent, setCodeSend] = useState(false);

    function sendCode(email) {
        sendRestoreCode(email).then(res => {
            if (res.status == 200) {
                setEmail(email);
                setCodeSend(true);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function restore(email, password, password_confirmation, code) {
        restorePassword(email, password, password_confirmation, code).then(res => {
            if (res.status == 200 && res.data.access && res.data.refresh) {
                setToken(res.data.access);
                setRefreshToken(res.data.refresh);
                dispatch(login({
                    user: res.data.id,
                    token: res.data.access,
                    refreshToken: res.data.refresh
                }));
                navigate("/");
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const RestoreEmailSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('restore.errorEmailRequired')),
    });

    const RestoreSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('restore.errorEmailRequired')),
        password: Yup.string()
            .required(t('restore.errorPasswordRequired')),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], t('restore.errorPasswordConfirmation'))
            .required(t('restore.errorPasswordConfirmationRequired')),
        code: Yup.string()
            .required(t('restore.errorCodeRequired')),
    });

    return (
        <div className="restore-container">
            {!isCodeSent && <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={RestoreEmailSchema}
                onSubmit={values => {
                    sendCode(values.email);
                }}>
                {({ touched, errors }) => (
                    <Form className="restore-form-container">
                        <div className="restore-form-header">
                            <h1>{t("restore.title")}</h1>
                        </div>
                        <div className="restore-form-body">
                            <div className="restore-form-input">
                                <Field type="text" placeholder={t("restore.emailPlaceholder")} className="restore"
                                    name="email" />
                                {errors.email && touched.email ? (<div className="restore-form-input-error">
                                    {errors.email}</div>) : null}
                            </div>
                        </div>
                        <div className="restore-form-footer">
                            <button className="restore-form-button" type="submit">{t("restore.sendCodeButton")}</button>
                        </div>
                        <div className="restore-form-footer">
                            <Link to="/registration" className="restore-form-link">{t("restore.registrationLink")}</Link>
                        </div>
                        <div className="restore-form-footer">
                            <Link to="/login" className="restore-form-link">{t("restore.loginLink")}</Link>
                        </div>
                    </Form>
                )}
            </Formik>}

            {isCodeSent && <Formik
                initialValues={{
                    email: email,
                    password: '',
                    password_confirmation: '',
                    code: '',
                }}
                validationSchema={RestoreSchema}
                onSubmit={values => {
                    restore(values.email, values.password, values.password_confirmation, values.code);
                }}>
                {({ touched, errors }) => (
                    <Form className="restore-form-container">
                        <div className="restore-form-header">
                            <h1>{t("restore.title")}</h1>
                        </div>
                        <div className="restore-form-body">
                            <div className="restore-form-input">
                                <Field type="hidden" placeholder={t("restore.emailPlaceholder")} className="restore"
                                    name="email" />
                                {errors.email && touched.email ? (<div className="restore-form-input-error">
                                    {errors.email}</div>) : null}
                            </div>
                            <div className="restore-form-input">
                                <Field type="password" placeholder={t("restore.passwordPlaceholder")} className="password"
                                    name="password" autocomplete="off" />
                                {errors.password && touched.password ? (<div className="restore-form-input-error">
                                    {errors.password}</div>) : null}
                            </div>
                            <div className="restore-form-input">
                                <Field type="password" placeholder={t("restore.passwordConfirmationPlaceholder")} className="password_confirmation"
                                    name="password_confirmation" autocomplete="off" />
                                {errors.password_confirmation && touched.password_confirmation ? (<div className="restore-form-input-error">
                                    {errors.password_confirmation}</div>) : null}
                            </div>
                            <div className="restore-form-input">
                                <Field type="number" placeholder={t("restore.codePlaceholder")} className="code"
                                    name="code" />
                                {errors.code && touched.code ? (<div className="restore-form-input-error">
                                    {errors.code}</div>) : null}
                            </div>
                        </div>
                        <div className="restore-form-footer">
                            <button className="restore-form-button" type="submit">{t("restore.restoreButton")}</button>
                        </div>
                        <div className="restore-form-footer">
                            <Link to="/registration" className="restore-form-link">{t("restore.registrationLink")}</Link>
                        </div>
                    </Form>
                )}
            </Formik>}

        </div>
    );
}