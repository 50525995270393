import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";

import 'dayjs/locale/ru';
dayjs.locale("ru");

export function getMonths(year) {
    let months = [];
    for (let i = 0; i < 12; i++) {
        months.push({
            date: dayjs(`${year}-${i + 1}-01`),
            name: dayjs(`${year}-${i + 1}-01`).locale('ru').format('MMMM'),
            days: [...Array(dayjs(`${year}-${i + 1}-01`).locale('ru').daysInMonth())].map((day, index) => {
                return dayjs(`${year}-${i + 1}-${index + 1}`);
            })
        });
    }
    return months;
}

export function getYearList() {
    const max = new Date().getFullYear() + 5;
    const min = max - 10;
    const yearList = [];
    for (let i = min; i <= max; i++) {
        yearList.push(i);
    }
    return yearList;
}

export function hasLeapYear(years) {
    dayjs.extend(isLeapYear);
    for (let i = 0; i < years.length; i++) {
        if (dayjs(`${years[i]}-1-1`).isLeapYear()) {
            return true;
        }
    }
    return false;
}

export function getRightDate(date) {
    date = dayjs(date);
    if (date.weekday(3).month() === date.month()) { 
        return dayjs(date.weekday(0))
    } else { return dayjs(date.weekday(7)) }
}
