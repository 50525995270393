import { http } from "./api";

export const sendLoginCredentialsAPI = (email, password) => {
    return http.post('/auth/login', {
        email: email,
        password: password
    });
};

export const sendRegisterCredentialsAPI = (email, password, code) => {
    return http.post('/auth/register', {
        email: email,
        password: password,
        code: code
    });
};

export const sendRegisterCode = (email) => {
    return http.post('/auth/register/code', {
        email: email
    });
}

export const refreshTokens = () => {
    return http.post('/auth/refresh', {
        refresh: localStorage.getItem('refreshToken')
    }, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('refreshToken')}`
        },
    });
}

export const sendRestoreCode = (email) => {
    return http.post('/auth/restore/code', {
        email: email,
    });
}

export const restorePassword = (email, password, passwordConfirmation, code) => {
    return http.post('/auth/restore', {
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        code: code,
    });
}

export const sendActivationCode = (email) => {
    return http.post('/auth/activate/code', {
        email: email,
    }, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
}

export const activateAccount = (email, code) => {
    return http.post('/auth/activate', {
        email: email,
        code: code,
    }, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
}