import dayjs from "dayjs";

import { DayAsList } from "../Day";
import 'dayjs/locale/ru';
import "./styles.scss"

function Week(props) {
    const periodDays = [...Array(7)];
    return (<div className="week">
        {periodDays.map((day, index) => {
            return <DayAsList key={index} date={dayjs(props.date).locale('ru').startOf('week').add(index, 'day')} />
        })}
    </div>)
}

export { Week };