import dayjs from "dayjs";
import { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames"
import { openThematicsModal } from "../../../store/modal";
import { EventDetail } from "../Event"
import { EventForm } from "../../forms/EventForm";
import { NoEvents } from "../../forms/NoEvents";
import { addEventAPI, getCategoriesAPI, updateEventAPI } from '../../../api';
import { addEvent, updateEvent, removeEvent, getEventsForDate, getEventsPositionsForDate } from "../../../store/events";
import { fetchedCategories } from "../../../store/categories";
import { deleteEventAPI } from "../../../api";
import Scrollbar from "../../utility/Scrollbar";
import OutsideClick from "../../../functions/OutsideClick";

import 'dayjs/locale/ru';
import "./DayAsWindow.scss";
import { addCategory } from "../../../store/categories";
import Grid from "../GridLayout/Grid";

function DayAsWindow(props) {
    const date = dayjs(props.date).format('YYYY-MM-DD');
    const events = useSelector(state => getEventsForDate(state, date));
    const filter = useSelector(state => state.settings.filter)
    const thematics = useSelector(state => state.thematics.thematics);

    const thematicsForDay = thematics.filter(
        thematic =>
            (dayjs(thematic.date_from).isBefore(props.date) &&
                dayjs(thematic.date_to).isAfter(props.date)) ||
            dayjs(thematic.date_from).isSame(props.date) ||
            dayjs(thematic.date_to).isSame(props.date)
    );
    const dispatch = useDispatch();
    const ref = useRef(null);
    const contentRef = useRef(null);
    const eventsRef = useRef(null);
    const scrollthumb = useRef(null);
    const weekdays = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
    const [height, setHeight] = useState(0)
    const [updateID, setUpdateID] = useState(props.updateID != null ? props.updateID : null);
    const [isFormVisible, setIsFormVisible] = useState(props.isUpdate);

    const [hiddenEventsBelowCount, setHiddenEventsBelowCount] = useState(0);
    const [hiddenEventsAboveCount, setHiddenEventsAboveCount] = useState(0);

    // useEffect(() => {
    //     countHiddenEvents();
    //     if (eventsRef && eventsRef.current) {
    //         setHeight(eventsRef.current?.offsetHeight)
    //     }
    // }, [eventsPositions]);

    useEffect(() => {
        countHiddenEvents();
        if (eventsRef && eventsRef.current) {
            setHeight(eventsRef.current?.offsetHeight)
        }
    });

    useEffect(() => {
        if (contentRef && contentRef.current) {

            return function cleanup() {
                const copyEventsRef = { ...contentRef };
                if (copyEventsRef && copyEventsRef.current) {
                    copyEventsRef.current.removeEventListener("wheel", scrollEvents);
                }
            }
        }
    });

    const edit = (eventId) => {
        toggleForm(true, eventId);
    };

    const remove = (id) => {
        deleteEventAPI(id)
            .then(
                res => {
                    dispatch(removeEvent(id));
                    toggleForm(false);
                }
            )
            .catch(err => console.log("ERROR", err));
    };

    const toggleForm = (isVisible, eventId) => {
        setUpdateID(eventId);
        setIsFormVisible(isVisible);
    }

    const countHiddenEvents = () => {
        if (eventsRef.current == null) return;
        const events = Array.from(eventsRef.current.children);
        const eventsHiddenBelow = events.filter((event) => {
            const eventHorizon = eventsRef.current.scrollTop + eventsRef.current.offsetHeight;
            const eventHead = event.offsetTop;

            return eventHead >= eventHorizon
        })
        const eventsHiddenAbove = events.filter((event) => {
            const eventHorizon = eventsRef.current.scrollTop;
            const eventFoot = event.offsetTop + event.offsetHeight;

            return eventFoot <= eventHorizon;
        })

        setHiddenEventsBelowCount(eventsHiddenBelow.length);
        setHiddenEventsAboveCount(eventsHiddenAbove.length);
    }

    const scrollEvents = (e, direction) => {
        e.stopPropagation();
        e.preventDefault();
        if (!eventsRef || !eventsRef.current) return;

        const events = eventsRef.current;
        let delta = Math.round(events.scrollTop / events.offsetHeight);
        let scrollTop = 0;

        if (e.deltaY > 0 || direction === "down") {
            scrollTop = events.offsetHeight * (delta + 1);
        } else if (e.deltaY < 0 || direction === "up") {
            scrollTop = events.offsetHeight * (delta - 1);
        }

        events.scrollTop = scrollTop;
    }
    const openThematicsForm = () => {
        dispatch(openThematicsModal({ date: props.date, thematic: thematicsForDay[0] }));
    }
    OutsideClick(ref, () => {
        if (props.onClose == null) return;
        props.onClose()
    })
    return (
        <div className="day-layout" ref={ref}>
            {!(isFormVisible && events.length === 0) &&
                <div
                    className={classNames({
                        "day-layout-content": true,
                        "day-layout-content_small": isFormVisible,
                    })}
                    ref={contentRef}
                >
                    <span className="day-layout-date">{dayjs(props.date).locale("ru").format("D MMMM")}</span>
                    <span className="day-layout-weekday">{weekdays[dayjs(props.date).locale("ru").weekday()]}</span>


                    {thematicsForDay.length > 0 &&
                        <button onClick={openThematicsForm} type="button" className="day-layout-thematic-exist" style={{ backgroundColor: `${thematicsForDay[0].color}80`, border: `1px solid ${thematicsForDay[0].color}` }}>
                            <span style={{ color: thematicsForDay[0].color, fontFamily: 'Roboto', fontSize: '16px', fontWeight: '500' }}>{thematicsForDay[0].name}</span>
                            <span style={{ fontFamily: 'Roboto', fontSize: '16px', color: '#535757' }}>({dayjs(thematicsForDay[0].date_from).format('DD.MM.YYYY')} - {dayjs(thematicsForDay[0].date_to).format('DD.MM.YYYY')})</span>
                        </button>}
                    {!thematicsForDay.length && <button onClick={openThematicsForm} type="button" className="day-layout-thematic-empty" ><span >+ Добавить тематику</span></button>}
                    {events.length > 0 &&
                        <div className="day-layout-events"
                            ref={eventsRef}
                            onScroll={countHiddenEvents}
                        >
                            <Scrollbar ref={scrollthumb} containerRef={eventsRef} updateOn={hiddenEventsBelowCount} />
                            <Grid events={events} date={date} edit={edit} dayHeight={height} />

                        </div>}

                    {events.length > 0 && <div className="day-layout-btns">
                        {!isFormVisible && <button className="btn btn-green day-layout-btn" onClick={() => toggleForm(true)}>Добавить запись</button>}
                        {hiddenEventsBelowCount > 0 && <button className="hidden-events-btn" onClick={(e) => scrollEvents(e, "down")}><i className="icon-arrow-down" />Скрыто {hiddenEventsBelowCount} событие</button>}
                        {hiddenEventsAboveCount > 0 && <button className="hidden-events-btn" onClick={(e) => scrollEvents(e, "up")}><i className="icon-arrow-down icon-arrow-down_reverse" />Скрыто {hiddenEventsAboveCount} событие</button>}
                    </div>}
                    {events.length === 0 && <NoEvents toggleForm={toggleForm} />}
                </div>
            }

            {
                isFormVisible && <EventForm
                    toggleForm={toggleForm}
                    eventId={updateID}
                    date={props.date}
                    dateEventsCount={events.length}
                />
            }
        </div >
    )
}

export { DayAsWindow };