import { useDrag, useDrop } from "react-dnd";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openModal } from "../../../store/modal";
import { DraggableTypes } from "../../utility/DraggableTypes";
import { copyEvent, getEventById, moveEvent, removeEvent, setOrder } from "../../../store/events";
import { copyEventAPI, deleteEventAPI, moveEventAPI, orderEventAPI } from "../../../api";
import dayjs from "dayjs";

function EventItemRounded({ eventId }) {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const event = useSelector(state => getEventById(state, eventId));
    const filter = useSelector(state => state.settings.filter);

    // const [, drag] = useDrag(() => ({
    //     type: !filter.categories.length ? DraggableTypes.EVENT : '',
    //     item: event,
    //     collect: monitor => ({
    //         isDragging: !!monitor.isDragging()
    //     }),
    //     end: (item, monitor) => {
    //         const dropResult = monitor.getDropResult();

    //         if (dropResult && dayjs(event.date).isSame(dropResult.date, 'day') && dropResult.order != null && monitor.didDrop()) {
    //             if (event.order !== dropResult.order) {
    //                 orderEventAPI(event.id, dropResult.order)
    //                     .then(res =>
    //                         dispatch(setOrder({
    //                             id: event.id,
    //                             order: res.data.order
    //                         }))
    //                     )
    //                     .catch(err => console.log("ERROR", err));
    //             }
    //         } else if (dropResult != null && !dayjs(event.date).isSame(dropResult.date, 'day') && monitor.didDrop()) {
    //             if (dropResult.copy) {
    //                 copyEventAPI(item, dropResult.date)
    //                     .then(res => dispatch(copyEvent({
    //                         id: item.id,
    //                         date: dropResult.date,
    //                         new_id: res.data.id
    //                     })))
    //                     .catch(err => console.log("ERROR", err));
    //             } else {
    //                 let order = dropResult.order;
    //                 if (order == null) {
    //                     order = events.filter(event => dayjs(event.date).isSame(dropResult.date, 'day')).length;
    //                 }
    //                 moveEventAPI(item.id, dropResult.date, order)
    //                     .then(res => {
    //                         dispatch(moveEvent({
    //                             id: res.data.id,
    //                             date: dayjs(res.data.date),
    //                             order: res.data.order
    //                         })
    //                         )
    //                     })
    //                     .catch(err => console.log("ERROR", err));
    //             }
    //         }
    //     }
    // }), [event.order, event.id]);

    // const [, drop] = useDrop({
    //     accept: DraggableTypes.EVENT,
    //     drop: (item, monitor) => {
    //         return {
    //             date: event.date,
    //             copy: false,
    //             order: event.order
    //         };
    //     },
    //     // hover(item, monitor) {
    //     //     if (!ref.current) {
    //     //         return;
    //     //     }
    //     //     const dragIndex = item.order;
    //     //     const hoverIndex = event.order;
    //     //     // Don't replace items with themselves
    //     //     if (dragIndex === hoverIndex) {
    //     //         return;
    //     //     }
    //     //     // Determine rectangle on screen
    //     //     const hoverBoundingRect = ref.current?.getBoundingClientRect();
    //     //     // Get vertical middle
    //     //     const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    //     //     // Determine mouse position
    //     //     const clientOffset = monitor.getClientOffset();
    //     //     // Get pixels to the top
    //     //     const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    //     //     // Only perform the move when the mouse has crossed half of the items height
    //     //     // When dragging downwards, only move when the cursor is below 50%
    //     //     // When dragging upwards, only move when the cursor is above 50%
    //     //     // Dragging downwards
    //     //     if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    //     //         return;
    //     //     }
    //     //     // Dragging upwards
    //     //     if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    //     //         return;
    //     //     }
    //     //     return dispatch(setOrder({
    //     //         id: item.id,
    //     //         order: hoverIndex
    //     //     }));
    //     // },
    // }, [event.order]);
    // drag(drop(ref));

    const [showButton, setShowButton] = useState(false);

    const edit = (id) => {
        dispatch(openModal({
            date: event.date,
            updateID: id,
            isNew: false
        }));
    };

    const remove = (id) => {
        deleteEventAPI(id)
            .then(
                res => dispatch(removeEvent(id))
            )
            .catch(err => console.log("ERROR", err));
    };
    return (
        <div ref={ref} className="day-content-event" onMouseOver={() => setShowButton(true)} onMouseEnter={() => setShowButton(true)} onMouseLeave={() => setShowButton(false)}>
            <div className="day-content-event-header-container">
                <div className="day-content-event-header" style={{ backgroundColor: event.color }}>
                    <i className={"icon-" + event.icon} />
                    <span className="day-content-event-title" dangerouslySetInnerHTML={{ __html: event.title }} />
                    <i className='icon-pen'
                        onClick={() => edit(event.id)}
                        style={{ cursor: 'pointer', marginTop: '2px', marginLeft: "12px" }} />
                </div>
                <div className="day-layout-remove-event" style={{ display: showButton ? 'inline-block' : 'none' }}>
                    <i className="icon-trash-can" onClick={() => remove(event.id)} />
                </div>
            </div>

            <div className="day-content-event-description">
                {event.description}
            </div>
        </div>
    );
}

export { EventItemRounded };