import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
    name: 'categories',
    initialState: {
        categories: [],
    },
    reducers: {
        fetchedCategories: (state, action) => {
            state.categories = action.payload;
        },
        addCategory: (state, action) => {
            state.categories.push(action.payload);
        },
        deleteCategory: (state, action) => {
            state.categories = state.categories.filter(category => category.id !== action.payload);
        },
        updateCategory: (state, action) => {
            const category = state.categories.find(category => category.id === action.payload.id);
            category.name = action.payload.name;
        },
    },
})

export const {
    addCategory,
    deleteCategory,
    updateCategory,
    fetchedCategories
} = categorySlice.actions;

export default categorySlice.reducer;