import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const thematicsSlice = createSlice({
    name: 'thematics',
    initialState: {
        thematics: [],
        highlightedThematics: null,
    },

    reducers: {
        fetchThematics: (state, action) => {
            state.thematics = action.payload;
        },
        addThematic: (state, action) => {
            state.thematics.push(action.payload);
        },
        updateThematic: (state, action) => {

            state.thematics = state.thematics.map((item, index) => {
                if (item.id === action.payload.id) {
                    return action.payload
                }
                return item
            })

        },
        deleteThematic: (state, action) => {
            state.thematics = state.thematics.filter(thematic => thematic.id !== action.payload);
        },
        setHighlightedThematic: (state, action) => {
            state.highlightedThematics = action.payload
        }
    },
})

export const {
    fetchThematics,
    addThematic,
    updateThematic,
    deleteThematic,
    setHighlightedThematic,
} = thematicsSlice.actions;

export default thematicsSlice.reducer;