import axios from "axios";
import 'dotenv/config'
import store from "../store";
import { logout } from "../store/auth";
import { setToken, setRefreshToken, removeToken, removeUser } from "../functions/TokenFunctions";
import { login } from "../store/auth";
import { refreshTokens } from "./authAPI";

const API_URL = "https://api.evocal.kz"
// const API_URL = "http://localhost:8080"

export const http = axios.create({
  baseURL: API_URL,
  timeout: 3000,
  headers: {
    "Content-Type": "application/json",
  }
});

const responseSuccessHandler = response => {
  return response;
};

const responseErrorHandler = error => {
  const originalRequest = error.config;
  if ((error.response.status === 401 || error.response.status === 500) && originalRequest.url === `/auth/refresh`) {
    removeToken();
    removeUser();
    store.dispatch(logout());

    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return refreshTokens().then((res) => {
      if (res.status == 200) {
        setToken(res.data.access);
        setRefreshToken(res.data.refresh);
        store.dispatch(login({
          user: res.data.id,
          token: res.data.access,
          refreshToken: res.data.refresh
        }));

        originalRequest.headers['Authorization'] = `Bearer ${res.data.access}`;
        return axios(originalRequest);
      }
    })
  }

  return Promise.reject(error);
};

http.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);
