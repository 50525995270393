import React, { useEffect } from 'react';
import "../../styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import { sendLoginCredentialsAPI } from "../../api";
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from "react-redux";
import { setRefreshToken, setToken, setUser } from '../../functions/TokenFunctions';
import { login } from "../../store/auth";


import { useTranslation } from "react-i18next";

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (
            localStorage.getItem('user') !== null &&
            localStorage.getItem('token') !== null) {

            dispatch(login({
                user: localStorage.getItem('user'),
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            }));
            navigate("/");
        }
    }, [])
    const auth = (email, password) => {
        sendLoginCredentialsAPI(email, password)
            .then(res => {
                setToken(res.data.access);
                setRefreshToken(res.data.refresh);
                dispatch(login({
                    user: res.data.id,
                    token: res.data.access,
                    refreshToken: res.data.refresh
                }));
                navigate("/");
            })
            .catch(err => {
                alert(err.response.data.detail)
            })
    }

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('login.errorEmailRequired')),
        password: Yup.string()
            .required(t('login.errorPasswordRequired'))
    });

    return (
        <div className="login-container">
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={LoginSchema}
                onSubmit={values => {
                    auth(values.email, values.password);
                }}>
                {({ touched, errors }) => (
                    <Form className="login-form-container">
                        <div className="login-form-header">
                            <h1>{t("login.title")}</h1>
                        </div>
                        <div className="login-form-body">
                            <div className="login-form-input">
                                <Field type="text" placeholder={t("login.placeholder")} className="email"
                                    name="email" />
                                {errors.email && touched.email ? (<div className="login-form-input-error">
                                    {errors.email}</div>) : null}
                            </div>
                            <div className="login-form-input">
                                <Field type="password" placeholder={t("login.passwordPlaceholder")} className="password"
                                    name="password" />
                                {errors.password && touched.password ? (<div className="login-form-input-error">
                                    {errors.password}</div>) : null}
                            </div>
                        </div>
                        <div className="login-form-footer">
                            <button className="login-form-button" type="submit">{t("login.button")}</button>
                        </div>
                        <div className="login-form-footer">
                            <Link to="/registration" className="login-form-link">{t("login.registrationLink")}</Link>
                        </div>
                        <div className="login-form-footer">
                            <Link to="/restore" className="login-form-link">{t("login.restoreLink")}</Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>);
}

export default Login;