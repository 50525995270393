import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ReactComponent as LayoutDefault } from '../../../styles/images/event-layout-default.svg';
import { ReactComponent as LayoutFullWidth } from '../../../styles/images/event-layout-full-width.svg';
import { ReactComponent as LayoutFullHeight } from '../../../styles/images/event-layout-full-height.svg';
import { ReactComponent as LayoutFull } from '../../../styles/images/event-layout-full.svg';
import IconClose from '../../../icons/icon-close.svg';

import store from '../../../store';
import EmojiSelect from "../EmojiSelect";
import { TextEditor } from '../TextEditor';
import { OptionSelect } from "../OptionSelect";
import { deleteCategoryAPI, getCategoriesAPI, updateCategoryAPI } from '../../../api/eventAttrsAPI';
import { addCategory, deleteCategory, fetchedCategories, updateCategory } from '../../../store/categories';
import SearchBar from '../SearchBar';

import './styles.scss'
import { addEvent, getEventById, getEventsForDate, removeEvent, setOrder, storeEvents, updateEvent } from '../../../store/events';
import { addEventAPI, deleteEventAPI, orderEventAPI, updateEventAPI } from '../../../api';
import { $CombinedState } from '@reduxjs/toolkit';

function EventForm({ eventId, toggleForm, date, dateEventsCount }) {
    const dispatch = useDispatch();

    date = date.set('hour', 12).set('minute', 0).set('second', 0);
    const dayDate = date.format('YYYY-MM-DD');
    const events = useSelector(state => getEventsForDate(state, dayDate));
    const initialEvent = useSelector(state => getEventById(state, eventId));
    const categories = useSelector(state => state.categories.categories)
    const colors = useSelector(state => state.colors.colors);
    const [title, setTitle] = useState(initialEvent ? initialEvent.title : '');
    const [description, setDescription] = useState(initialEvent ? initialEvent.description : '');
    const [color, setColor] = useState(initialEvent ? initialEvent.color : colors[1].color);
    const [rating, setRating] = useState(initialEvent ? initialEvent.rating : 0);
    const [icon, setIcon] = useState(initialEvent ? initialEvent.icon : '');
    const [fontSize,] = useState(initialEvent ? initialEvent.fontSize : "1.5rem");
    const [height, setHeight] = useState(initialEvent ? initialEvent.height : 50);
    const [width, setWidth] = useState(initialEvent ? initialEvent.width : 50);
    const [category, setCategory] = useState(
        initialEvent &&
            initialEvent.category &&
            categories.find(category => category.name === initialEvent.category) ?
            {
                id: categories.find(category => category.name === initialEvent.category).id,
                name: initialEvent.category,
            } :
            { id: null, name: '', isEditable: false });

    const [confirmEventDeletion, setConfirmEventDeletion] = useState(false);
    const [emoji, setEmoji] = useState(null);
    const [posX, setPosX] = useState(initialEvent ? initialEvent.pos_x : 0);
    const [posY, setPosY] = useState(initialEvent ? initialEvent.pos_y : 0);

    useEffect(() => {
        if (initialEvent) {
            setTitle(initialEvent.title);
            setDescription(initialEvent.description);
            setColor(initialEvent.color);
            setRating(initialEvent.rating);
            setIcon(initialEvent.icon);
            setCategory(initialEvent.category);
            setEmoji(initialEvent.emoji);
            setWidth(initialEvent.width);
            setHeight(initialEvent.height);
            setPosX(initialEvent.pos_x);
            setPosY(initialEvent.pos_y);
            let categoryData = categories.find(category => category.name === initialEvent.category);
            if (categoryData) {
                setCategory({ id: categoryData.id, name: categoryData.name, isEditable: true });
            } else {
                setCategory({ id: null, name: initialEvent.category, isEditable: false });
            }
        }
    }, [initialEvent])

    const setEventLayout = (width, height) => {
        setWidth(width);
        setHeight(height);
    }

    useEffect(() => {
        const e = events.filter(id => id != eventId).
            map(id => {
                return getEventById(store.getState(), id);
            })
        const { x, y } = getFirstOpenSpace(e);
        setPosX(x);
        setPosY(y);
    }, [width])


    function getFirstOpenSpace(events) {
        events.sort((a, b) => a.pos_y - b.pos_y || a.pos_x - b.pos_x);

        let map = {};
        const cellSize = 50;
        const rowWidth = 100;
        events.map(event => {
            for (let i = 0; i < event.height / cellSize; i++) {
                for (let j = 0; j < event.width / cellSize; j++) {
                    map[`${event.pos_x + j}-${event.pos_y + i}`] = true;
                }
            }
        })

        const lastEvent = events[events.length - 1];
        if (!lastEvent) {
            return { x: 0, y: 0 }
        }

        const lastRow = lastEvent.y + (lastEvent.height / cellSize) || 0;
        for (let i = 0; i <= lastRow + (height / cellSize); i++) {
            for (let j = 0; j < rowWidth / cellSize; j++) {
                if (map[`${j}-${i}`]) {
                    continue;
                }

                let enoughSpace = true;
                for (let k = 0; k < width / cellSize; k++) {
                    if (enoughSpace === false) break;

                    if (j + k >= rowWidth / cellSize) {
                        enoughSpace = false;
                        break
                    }

                    for (let l = 0; l < height / cellSize; l++) {
                        if (map[`${j + k}-${i + l}`]) {
                            enoughSpace = false;
                            break;
                        }
                    }
                }

                if (enoughSpace) {
                    return { x: j, y: i }
                }
            }
        }
    }

    const createEvent = () => {
        let eventTitle = title;
        let categoryData = category.id;
        if (category.id === null && category.name.length > 0) {
            categoryData = category.name;
        }
        // changing placeholder color to real color
        colors.map(c => {
            if (c.placeholder != null) {
                let regexp = new RegExp(c.placeholder.toLowerCase(), "g");
                eventTitle = eventTitle.replace(regexp, c.color.toLowerCase());
            }
        })

        addEventAPI({
            title: eventTitle,
            description,
            date,
            rating,
            icon,
            color,
            fontSize,
            width,
            height,
            category: category.name,
            order: dateEventsCount,
            pos_x: posX,
            pos_y: posY,
        }).then(res => {
            if (typeof categoryData === "string") {
                dispatch(addCategory({
                    id: res.data.category,
                    name: category.name
                }))
            }

            dispatch(addEvent({
                id: res.data.id,
                title: eventTitle,
                description,
                date,
                rating,
                icon,
                color,
                fontSize,
                width,
                height,
                pos_x: res.data.pos_x,
                pos_y: res.data.pos_y,
                category: res.data.category,
                order: res.data.order,
            }))
        })

        toggleForm(false);
    };

    const editEvent = () => {
        let eventTitle = title;
        // changing placeholder color to real color
        colors.map(c => {
            if (c.placeholder != null) {
                let regexp = new RegExp(c.placeholder.toLowerCase(), "g");
                eventTitle = eventTitle.replace(regexp, c.color.toLowerCase());
            }
        })

        updateEventAPI({
            id: eventId,
            title: eventTitle,
            description,
            date,
            rating,
            icon,
            color,
            fontSize,
            width,
            height,
            category: category.name,
            pos_x: posX,
            pos_y: posY,
        }).then(res => {
            dispatch(updateEvent({
                id: res.data.id,
                title: eventTitle,
                description,
                date,
                rating,
                icon,
                color,
                fontSize,
                width,
                height,
                pos_x: posX,
                pos_y: posY,
                category: category.name,
            }));

            getCategoriesAPI().then(res => {
                dispatch(fetchedCategories(res.data));
            })
        })

        toggleForm(false);
    }

    const save = () => {
        if (eventId != null) {
            editEvent()
        } else {
            createEvent()
        }
    }

    const remove = (id) => {
        deleteEventAPI(id)
            .then(
                res => {
                    dispatch(removeEvent(id));
                    toggleForm(false);
                }
            )
            .catch(err => console.log("ERROR", err));
    };

    const handleDelete = () => {
        if (confirmEventDeletion) {
            remove(eventId);
            return;
        }
        toggleDeleteBtn();
    }

    const toggleDeleteBtn = () => {
        setConfirmEventDeletion(!confirmEventDeletion);
    }

    const handleCategoryEdit = (category) => {
        if (category.isEditable) {
            updateCategoryAPI(category.id, category.name).then(res => {
                if (res.status === 200)
                    dispatch(updateCategory(category));
            })
        }
    }

    const handleCategoryDelete = (category) => {
        deleteCategoryAPI(category.id).then(res => {
            if (res.status === 204)
                dispatch(deleteCategory(category.id));
            if (category.id === category.id) {
                setCategory({ id: null, name: "" })
            }
        });
    }

    const addEmojiToTitle = (emoji) => {
        setEmoji(emoji);
    }

    return (
        <div className="new-day-event-form">
            <div className="new-day-event-form-header">
                {(eventId != null) && "Редактирование события"}
                {(eventId == null) && "Новое событие"}
                <button className="btn close-btn" onClick={() => toggleForm(false, null)} >
                    <img src={IconClose} alt="close" />
                </button>
            </div>



            <div className="new-day-event-form-title-options">
                <TextEditor
                    eventId={eventId}
                    title={title}
                    color={color}
                    setTitle={setTitle}
                    setColor={setColor}
                    setEmoji={setEmoji}
                    emoji={emoji}
                />
            </div>

            <SearchBar
                options={categories.map(category => {
                    return {
                        id: category.id,
                        name: category.name,
                    }
                })}
                setValue={setCategory}
                handleOptionEdit={handleCategoryEdit}
                handleOptionDelete={handleCategoryDelete}
                value={category}
                classnames="category"
                placeholder={"Категория"}
            />

            <EmojiSelect addEmoji={addEmojiToTitle} />

            <div className="event-layout-input">
                <div className="title">Расположение события</div>
                <input
                    type="radio"
                    name="event-layout"
                    id="layout-default"
                    className="input-hidden"
                    onChange={() => setEventLayout(50, 50)}
                    checked={width === 50 && height === 50} />
                <label htmlFor="layout-default">
                    <LayoutDefault />
                </label>

                <input
                    type="radio"
                    name="event-layout"
                    id="layout-full-width"
                    className="input-hidden"
                    onChange={() => setEventLayout(100, 50)}
                    checked={width === 100 && height === 50} />
                <label htmlFor="layout-full-width">
                    <LayoutFullWidth />
                </label>

                <input
                    type="radio"
                    name="event-layout"
                    id="layout-full-height"
                    className="input-hidden"
                    onChange={() => setEventLayout(50, 100)}
                    checked={width === 50 && height === 100} />
                <label htmlFor="layout-full-height">
                    <LayoutFullHeight />
                </label>

                <input
                    type="radio"
                    name="event-layout"
                    id="layout-full"
                    className="input-hidden"
                    onChange={() => setEventLayout(100, 100)}
                    checked={width === 100 && height === 100} />
                <label htmlFor="layout-full">
                    <LayoutFull />
                </label>
            </div>

            <div className="event-description">
                <textarea placeholder="Добавьте описание" value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>

            <button className="btn btn-green btn-save" onClick={() => save()}>Сохранить</button>
            {
                eventId != null &&
                <button
                    className={classNames({
                        "btn": true,
                        "btn-delete": true,
                        "btn-red": confirmEventDeletion,
                    })}
                    onClick={() => handleDelete()}
                >
                    <i className={classNames({
                        "icon-bin": true,
                        "icon-bin_active": confirmEventDeletion,
                    })} />
                    {confirmEventDeletion && "Да, удалить"}
                </button>

            }
        </div >
    )
}

export { EventForm };