
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import classNames from "classnames";
import { useState, useEffect } from "react";

import { openModal } from "../../../store/modal";
import { DraggableTypes } from "../../utility/DraggableTypes";
import { copyEvent, getEventById, cutEvent, setEventsOrders, setOrder, swapEventsOrders, bufferType } from "../../../store/events";
import { setEventsToDelete } from "../../../store/settings";
import { copyEventAPI, moveEventAPI, orderEventAPI, swapEventsOrder } from "../../../api/eventAPI";
import styles from '../../utility/checkbox.module.scss'

function EventItem({ id, eventsInDateCount, hasOverlap, eventsPositions, maxRows, copyKeyPressed, cutKeyPressed, isDragging }) {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const event = useSelector(state => getEventById(state, id));
    const eventDeletion = useSelector(state => state.settings.eventDeletion)
    const design = useSelector(state => state.settings.design)

    const filter = useSelector(state => state.settings.filter);
    const isCheckedForDeletion = useSelector(state => state.settings.eventsToDelete.includes(event.id));
    const buffer = useSelector(state => state.events.buffer);
    const [isEventCheckedForDeletion, setEventCheckedForDeletion] = useState(isCheckedForDeletion);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [isCut, setIsCut] = useState(false);
    const [{ isBeingMoved }, drag] = useDrag(() => {
        return {
            type: DraggableTypes.EVENT,
            item: event,
            collect: monitor => ({
                isBeingMoved: !!monitor.isDragging()
            }),
            canDrag: isDragging

        }
    }, [isDragging]);
    useEffect(() => {
        if (buffer.id != event.id) {
            setIsCopied(false);
            setIsCut(false);
            return
        }

        if (buffer.type === bufferType.COPY) {
            setIsCopied(true);
            setIsCut(false);
        } else if (buffer.type === bufferType.CUT) {
            setIsCut(true);
            setIsCopied(false);
        } else {
            setIsCopied(false);
            setIsCut(false);
        }
    }, [buffer]);

    useEffect(() => {
        if (!isMouseOver) return;
        if (copyKeyPressed) {
            dispatch(copyEvent(event.id));
        } else if (cutKeyPressed) {
            dispatch(cutEvent(event.id));
        }
    }, [copyKeyPressed, cutKeyPressed]);

    useEffect(() => {
        setEventCheckedForDeletion(isCheckedForDeletion);
    }, [isCheckedForDeletion]);

    if (filter.categories.length > 0 && !filter.categories.includes(event.category)) return (<></>);

    const getTitle = () => {
        let title = event.title;
        title = title.replace(/">/g, "\"><span>").replace(/<\//g, "</span></");

        return title;
    }

    const shadeColor = (color, percent) => {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    const open = () => {
        dispatch(openModal({
            date: event.date,
            updateID: null,
            isNew: false
        }));
    }

    const edit = (id) => {
        dispatch(openModal({
            date: event.date,
            updateID: id,
            isNew: false
        }));
    }

    const clickHandler = (e) => {
        if (isDragging) return;

        e.stopPropagation();
        if (e.ctrlKey || e.metaKey) {
            edit(event.id);
        } else {
            open();
        }
    };

    const setEventToDelete = (e, value) => {
        e.stopPropagation();
        dispatch(setEventsToDelete(value))
        setEventCheckedForDeletion(e.target.checked)
    }

    const onMouseEnter = () => {
        setIsMouseOver(true);
    }

    const onMouseLeave = () => {
        setIsMouseOver(false);
    }

    return <div ref={drag}
        onClick={clickHandler}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classNames({
            "calendar-event": true,
            "copied-event": isCopied,
            "cut-event": isCut,
            "gradient-event": design == 'Skeuomorphism' ? true : false
        })}
        style={{
            border: design == 'Skeuomorphism' ? `1px solid black` : '',
            background: design == 'Skeuomorphism' ? `linear-gradient(180deg, ${event.color} 50%,  ${shadeColor(event.color, -10)} 50%` : event.color,
            width: '100%',
            height: '100%',
            position: 'relative',
        }}>

        <div className="calendar-event-title calendar-event-delete" >
            <span style={{ inset: '4px 0 0 3px', overflow: 'hidden', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: getTitle() }}></span>
            {
                eventDeletion &&
                <input type='checkbox'
                    className={styles.checkbox}
                    checked={isEventCheckedForDeletion}
                    onClick={(e) => { setEventToDelete(e, event.id) }} />
            }
        </div>
        <i className={"icon-" + event.icon} />
    </div>
}

export { EventItem };