import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import 'dayjs/locale/ru';
import { getRightDate } from "../../functions/serviceFunctions";
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrAfter)


dayjs.locale('ru');

const getWeekday = (date) => {
    const weekday = date.$W;
    if (weekday === 0) return 6;
    return weekday - 1;
}
export const getPeriodDays = (initialDate, period) => {
    const startDate = getRightDate(initialDate);

    const endDate = getRightDate(dayjs(initialDate).add(period, 'month'));
    return endDate.diff(startDate, 'day');
}
export const getNumberOfDaysInPeriod = (initialDate, period) => {
    const startDate = dayjs(initialDate);
    const endDate = dayjs(initialDate).add(period, 'month');

    return endDate.diff(startDate, 'day');
};

export const currentPeriodDays = (periodDays, initialDate) => [...Array(periodDays)].map((day, index) => {
    const date = dayjs(initialDate).date(initialDate.date()).add(index, 'day')
    return {
        date: date,
        dayOfMonth: date.format('D'),
        isCurrentPeriod: true,
    }
});

export const daysForPreviousPeriod = (initialDate, periodDays) => {
    const firstDayOfTheMonthWeekday = getWeekday(periodDays[0].date);
    const previousMonth = dayjs(initialDate).date(1).subtract(1, 'month');

    const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday;//Если первый день месяца воскр, инициализируется значения 6. Значит пред. 6 дней в неделе не из этого периода. Если понедельник то 0.
    const previousMonthLastMondayDayOfMonth = dayjs(periodDays[0].date)
        .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
        .date();
    return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
        return {
            date: dayjs(
                `${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index
                }`, 'YYYY-MM-DD'
            ),
            dayOfMonth: previousMonthLastMondayDayOfMonth + index,
            isCurrentPeriod: false
        };
    });
}

export const daysForNextPeriod = (initialDate, periodDays, period) => {
    const lastDayOfTheMonthWeekday = getWeekday(periodDays[periodDays.length - 1].date);

    const nextMonth = dayjs(initialDate).add(period, 'month');

    const visibleNumberOfDaysFromNextMonth = 6 - lastDayOfTheMonthWeekday;
    return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
            date: dayjs(
                `${nextMonth.year()}-${nextMonth.month() + 1}-01`, 'YYYY-MM-DD'
            ).add(index, 'day'),
            dayOfMonth: index + 1,
            isCurrentPeriod: false
        };
    })
}

export const getWeekDays = (days) => days.reduce((acc, day) => {
    let weekDay = getWeekday(day.date);
    if (!acc[weekDay]) {
        acc[weekDay] = [];
    }
    acc[weekDay].push(day);
    return acc;
}, {});

export const getWeekNumbers = (weekDays) => weekDays[0].map((day) => {
    return {
        date: day.date,
        weekNumber: dayjs(day.date).week()
    }
});

export const isPast = date => dayjs(date).isBefore(dayjs(), 'day');

export const isNotThisMonthAndYear = (date, initialDate) => {
    return dayjs(date).year() !== dayjs(initialDate).year() || dayjs(date).month() !== dayjs(initialDate).month();
}

export const isPastWeek = date => dayjs(date).isBefore(dayjs(), 'week')

export const isCurrentWeekAndYear = (date) => {
    return dayjs(date).isSame(dayjs(), 'year') && dayjs(dayjs(date).locale('ru').week()).isSame(dayjs().locale('ru').week());
}

export const isFirstDayOfTheCurrentWeek = (date) => {
    if (!isCurrentWeekAndYear(date)) return
    return dayjs(date).locale('ru').weekday() === 0;
}

export const isLastDayOfTheCurrentWeek = (date) => {
    if (!isCurrentWeekAndYear(date)) return
    return dayjs(date).locale('ru').weekday() === 6;
}

export const isCurrentDay = (date) => {
    return dayjs(date).isSame(dayjs(), 'day');
}

export const monthWeeksLength = (days, weekNumbers, weeksCount) => {
    let weeks = []
    days.forEach((day) => {
        if (dayjs(day.date).weekday() === 3 && dayjs(day.date).date() < 8) {
            weeks.push({
                weekNumber: dayjs(day.date).week(),
                date: day.date
            })
        }
    })
    weeks = weeks.map((week) => {
        return {
            weekNumber: weekNumbers.findIndex((w) => w.weekNumber === week.weekNumber),
            date: week.date
        }
    })
    weeks.push({
        weekNumber: weeksCount,
        date: null
    })
    weeks = weeks.map((week, index) => {
        if (week.weekNumber !== weeksCount) {
            return {
                monthLength: weeks[index + 1].weekNumber - week.weekNumber,
                date: () => (<span><span
                    className="bold">{capitalize(dayjs(week.date).format("MMMM"))}</span> {dayjs(week.date).format("YYYY")}</span>),
                monthNum: week.date.month(),
                year: week.date.year()
            }
        }
        return 0
    }).filter((week) => week !== 0)
    return weeks
}

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const periodSplit = (period, days) => {
    switch (period) {
        case 4: {
            const weekNumber = days.filter((day) => day.isCurrentPeriod === true)
                .filter((day) => dayjs(day.date).date() === 1)
                .map((day) => dayjs(day.date).week())[2];
            const firstDayOfWeek = days.find((day) => dayjs(day.date).week() === weekNumber);
            return days.reduce(([pass, fail], elem) => {
                return dayjs(elem.date).isBefore(firstDayOfWeek.date)
                    ? [[...pass, elem], fail] : [pass, [...fail, elem]];
            }, [[], []])
        }
        case 6: {
            const weekNumber = days.filter((day) => day.isCurrentPeriod === true)
                .filter((day) => dayjs(day.date).weekday() === 3 && dayjs(day.date).date() < 8)
                .map((day) => dayjs(day.date).week())[3];
            const firstDayOfWeek = days.find((day) => dayjs(day.date).week() === weekNumber);
            return days.reduce(([pass, fail], elem) => {
                return dayjs(elem.date).isBefore(firstDayOfWeek.date)
                    ? [[...pass, elem], fail] : [pass, [...fail, elem]];
            }, [[], []])
        }
        case 12: {
            const firstDaysOfMonths = days.filter((day) => day.isCurrentPeriod === true)
                .filter((day) => dayjs(day.date).weekday() === 3 && dayjs(day.date).date() < 8).reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / 4)

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []
                    }

                    resultArray[chunkIndex].push(item)

                    return resultArray
                }, []);
            return firstDaysOfMonths.map((firstDayOfMonth, i) => {
                switch (i) {
                    case 0:
                        return days
                            .filter(day => dayjs(day.date).isBefore(dayjs(firstDaysOfMonths[1][0].date).startOf('week')))
                    case 1:
                        return days
                            .filter(day => dayjs(day.date).isBefore(dayjs(firstDaysOfMonths[2][0].date).startOf('week')) &&
                                dayjs(day.date).isSameOrAfter(dayjs(firstDaysOfMonths[1][0].date).startOf('week')))
                    case 2:
                        return days
                            .filter(day => dayjs(day.date).isSameOrAfter(dayjs(firstDayOfMonth[0].date).startOf('week')))
                    default:
                        return null
                }

            })
        }
        default:
            return [days]
    }
}

const removeFirstWeekFromRow = (row) => {
    row.monthsLength[0].monthLength--;
    row.weekNumbers = row.weekNumbers.slice(1);
    Object.entries(row.weekdays).forEach(([weekday, value]) => {
        row.weekdays[weekday] = row.weekdays[weekday].slice(1);
    })
    return row
}
const removeLastWeekFromRow = (row) => {
    row.monthsLength[row.monthsLength.length - 1].monthLength--;
    row.weekNumbers = row.weekNumbers.slice(0, -1);
    Object.entries(row.weekdays).forEach(([weekday, value]) => {
        row.weekdays[weekday] = row.weekdays[weekday].slice(0, -1);
    })

    return row
}

const moveWeekFromRowToRow = (fromRow, toRow, forward) => {
    if (!forward) {
        fromRow.monthsLength[0].monthLength--;
        toRow.monthsLength[toRow.monthsLength.length - 1].monthLength++;
        toRow.monthsLength[toRow.monthsLength.length - 1].plusLastWeek = true;
        toRow.weekNumbers.push({ ...fromRow.weekNumbers.shift(), anotherMonthWeekLeft: true });
        Object.entries(fromRow.weekdays).forEach(([weekday, value]) => {
            toRow.weekdays[weekday].push(fromRow.weekdays[weekday].shift());
        })
    } else {
        fromRow.monthsLength[fromRow.monthsLength.length - 1].monthLength--;
        toRow.monthsLength[0].monthLength++;
        toRow.monthsLength[0].plusFirstWeek = true;
        toRow.weekNumbers.unshift({ ...fromRow.weekNumbers.pop(), anotherMonthWeekRight: true });
        Object.entries(fromRow.weekdays).forEach(([weekday, value]) => {
            toRow.weekdays[weekday].unshift(fromRow.weekdays[weekday].pop());
        })
    }

    return [fromRow, toRow]
}

const expandRowFromStart = (row, weeks = 1) => {
    row.monthsLength[0].monthLength += weeks;
    row.monthsLength[0].plusFirstWeek = true;
    for (let i = 1; i <= weeks; i++) {
        const previousWeekFirstDay = row.weekNumbers[0].date.add(-7, "days");
        row.weekNumbers.unshift({ date: previousWeekFirstDay, weekNumber: previousWeekFirstDay.week(), anotherMonthWeekRight: true });
        Object.entries(row.weekdays).forEach(([weekday, value]) => {
            const currentDayPreviousWeek = row.weekdays[weekday][0].date;
            row.weekdays[weekday].unshift({
                date: currentDayPreviousWeek.add(-7, "days"),
                dayOfMonth: currentDayPreviousWeek.add(-7, "days").date(),
                isCurrentPeriod: false
            });
        });
    }
}

const expandRowFromEnd = (row, weeks = 1) => {
    row.monthsLength[row.monthsLength.length - 1].monthLength += weeks;
    row.monthsLength[row.monthsLength.length - 1].plusLastWeek = true;
    for (let i = 1; i <= weeks; i++) {
        const nextWeekFirstDay = row.weekNumbers[row.weekNumbers.length - 1].date.add(7, "days");
        row.weekNumbers.push({ date: nextWeekFirstDay, weekNumber: nextWeekFirstDay.week(), anotherMonthWeekLeft: true });
        Object.entries(row.weekdays).forEach(([weekday, value]) => {
            const currentDayNextWeek = row.weekdays[weekday][row.weekdays[weekday].length - 1].date;
            row.weekdays[weekday].push({
                date: currentDayNextWeek.add(7, "days"),
                dayOfMonth: currentDayNextWeek.add(7, "days").date(),
                isCurrentPeriod: false,
            });
        });
    }
}
export const equalizeRowsByWeeks = (periods, period, remove) => {
    switch (period) {
        case 6: {
            const weeksIn1Row = periods[0].monthsLength.reduce((acc, row) => acc + row.monthLength, 0);
            const weeksIn2Row = periods[1].monthsLength.reduce((acc, row) => acc + row.monthLength, 0);
            if (weeksIn1Row === 12) {
                moveWeekFromRowToRow(periods[1], periods[0]);
            } else {
                if (weeksIn1Row === 14) {
                    periods[0] = removeFirstWeekFromRow(periods[0]);
                }
                if (weeksIn2Row === 14) {
                    periods[1] = removeLastWeekFromRow(periods[1]);
                }
            }
            return periods;
        }
        case 12: {
            const weeksIn1Row = periods[0].monthsLength.reduce((acc, row) => acc + row.monthLength, 0);
            const weeksIn2Row = periods[1].monthsLength.reduce((acc, row) => acc + row.monthLength, 0);
            const weeksIn3Row = periods[2].monthsLength.reduce((acc, row) => acc + row.monthLength, 0);
            if (weeksIn1Row === 18 && weeksIn2Row === 17 && weeksIn3Row === 18) {
                if (remove) {
                    removeFirstWeekFromRow(periods[0]);
                    removeLastWeekFromRow(periods[2]);
                } else {
                    moveWeekFromRowToRow(periods[2], periods[1]);
                    expandRowFromEnd(periods[2])
                }
            }
            else if (weeksIn1Row === 17 && weeksIn2Row === 17 && weeksIn3Row === 18) {
                if (remove) {
                    removeLastWeekFromRow(periods[2]);
                } else {
                    expandRowFromStart(periods[0]);
                    expandRowFromEnd(periods[2]);
                    moveWeekFromRowToRow(periods[2], periods[1]);
                }
            }
            else if (weeksIn1Row === 17 && weeksIn2Row === 18 && weeksIn3Row === 17) {
                if (remove) {
                    moveWeekFromRowToRow(periods[1], periods[2], true);
                    removeLastWeekFromRow(periods[2])
                } else {
                    expandRowFromStart(periods[0]);
                    expandRowFromEnd(periods[2]);
                }
            }
            else if (weeksIn1Row === 18 && weeksIn2Row === 17 && weeksIn3Row === 17) {
                if (remove) {
                    removeFirstWeekFromRow(periods[0])
                } else {
                    expandRowFromStart(periods[0]);
                    moveWeekFromRowToRow(periods[0], periods[1], true);
                    expandRowFromEnd(periods[2])
                }
            }
            return periods;
        }
        default:
            return periods;
    }
}