import classNames from "classnames";

import "./styles.scss"

function Filter({ values, selectedValues, filter, clearFilter }) {

    return (
        <div className="filter">
            <button
                className={classNames({
                    "filter-item": true,
                    "filter-item_selected": selectedValues.length === 0
                })}
                onClick={(e) => { e.preventDefault(); clearFilter() }}
            >
                Показать все
            </button>
            <button
                className={classNames({
                    "filter-item": true,
                    "filter-item_selected": selectedValues.includes("")
                })}
                onClick={(e) => { e.preventDefault(); filter("") }}
            >
                Без категории
            </button>
            {values.map(value => (
                <button
                    key={value.name}
                    className={classNames({
                        "filter-item": true,
                        "filter-item_selected": selectedValues.includes(value.name)
                    })}
                    onClick={(e) => { e.preventDefault(); filter(value.name) }}
                >
                    {value.name}
                </button>
            ))}
        </div>
    );
}

export default Filter;