import { http } from "./api";

export const getIconsAPI = () => {
  return http.get('/icons/',
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
};

export const getColorsAPI = () => {
  return http.get('/colors/',
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
};

export const getCategoriesAPI = () => {
  return http.get('/categories/',

    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
};

export const getCategoryAPI = (id) => {
  return http.get(`/categories/${id}/`,

    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
};

export const addCategoryAPI = (name) => {
  return http.post('/categories/',
    {
      "name": name
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
};

export const updateCategoryAPI = (id, name) => {
  return http.put(`/categories/${id}/`,
    {
      "id": id,
      "name": name
    },
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
};

export const deleteCategoryAPI = (id) => {
  return http.delete(`/categories/${id}/`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
};