import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify'
import classNames from 'classnames';

import { OptionSelect } from "../OptionSelect";
import RangeSlider from '../RangeSlider';

import './styles.scss';

function TextEditor({ eventId, title, color, setTitle, setColor, emoji, setEmoji }) {
    const sanitizer = DOMPurify.sanitize;
    const titleRef = useRef(null);
    const colors = useSelector(state => state.colors.colors);
    const fontSizes = [
        { value: 1, label: <span className="font-size-option">10</span> },
        { value: 2, label: <span className="font-size-option">13</span> },
        { value: 3, label: <span className="font-size-option">16</span> },
        { value: 4, label: <span className="font-size-option">18</span> },
        { value: 5, label: <span className="font-size-option">24</span> },
        { value: 6, label: <span className="font-size-option">32</span> },
        { value: 7, label: <span className="font-size-option">48</span> },
    ]

    const maxTitleLen = 200;
    const [titleLenCounter, setTitleLenCounter] = useState(0);
    const [isBold, setIsBold] = useState(false);
    const [isCursive, setIsCursive] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);

    const getTitle = () => {
        let newTitle = title;
        colors.map(c => {
            if (c.placeholder != null)
                newTitle = newTitle.replace(c.color.toLowerCase(), c.placeholder.toLowerCase());
        })
        return newTitle
    }

    const getMatchingColor = (color) => {
        color = color.toUpperCase();
        return colors.find(c => (c.color === color || c.placeholder === color));
    }

    const getInitialColor = () => {
        const pos = title.search(/#[0-9a-zA-Z]{6}/);

        let color = (pos !== -1) ? title.slice(pos, pos + 7) : colors[0].color;
        let match = getMatchingColor(color);
        if (match)
            return match.color;

        return color
    }

    const getInitialFontSize = () => {
        const pos = title.search(/size="[0-9]{1}"/);
        return (pos === -1) ? 3 : parseInt(title[pos + 6]);
    }

    const [initialFontColor, setInitialFontColor] = useState(getInitialColor());
    const [initialFontSize, setInitialFontSize] = useState(getInitialFontSize());

    useEffect(() => {
        if (titleRef && titleRef.current) {
            titleRef.current.addEventListener("paste", handleTitlePaste)
            return function cleanup() {
                const copyTitleRef = { ...titleRef };
                if (copyTitleRef && copyTitleRef.current) {
                    copyTitleRef.current.removeEventListener("paste", handleTitlePaste);
                }
            }
        }
    });

    useEffect(() => {
        handleTitleLenCounter();
        setInitialFontColor(getInitialColor());
        setInitialFontSize(getInitialFontSize());
    }, [title]);


    const onChangeTitle = (e) => {
        let title = sanitizer(e.target.innerHTML);
        setTitle(title)
    }

    const bold = (e) => {
        e.preventDefault();

        document.execCommand("bold", false, "");
        setIsBold(!isBold);
    }

    const cursive = (e) => {
        e.preventDefault();
        document.execCommand("italic", false, "");
        setIsCursive(!isCursive);
    }

    const underline = (e) => {
        e.preventDefault();
        document.execCommand("underline", false, "");
        setIsUnderline(!isUnderline);
    }

    const setFontSize = (e) => {
        e.preventDefault();
        const input = titleRef.current;
        if (input.length === 0) {
            selectWholeText(input);
        }

        const fontSize = e.target.value;
        setInitialFontSize(fontSize);
        document.execCommand("fontsize", false, e.target.value);
        input.focus();
    }

    const setFontSizeByValue = (value) => {
        const input = titleRef.current;
        if (input.length === 0) {
            selectWholeText(input);
        }

        if (window.getSelection) {
            const selection = window.getSelection();
            if (selection.focusNode) {
                const length = selection.getRangeAt(0).endOffset - selection.getRangeAt(0).startOffset;

                if (length == 0) {
                    selectWholeText(input);
                }
            }
        }

        setInitialFontSize(value);
        document.execCommand("fontsize", false, value);
        input.focus();
    }

    const setFontColor = (color) => {
        const input = titleRef.current;
        if (input.length === 0) {
            selectWholeText(input);
        }
        setInitialFontColor(color);

        // replace color with placeholder color
        let match = getMatchingColor(color);
        if (match && match.placeholder) {
            color = match.placeholder;
        }

        document.execCommand("forecolor", false, color);
    }

    const selectWholeText = (div) => {
        let range, sel;

        if (window.getSelection && document.createRange) {
            range = document.createRange();
            range.selectNodeContents(div);
            sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(div);
            range.select();
        }
    }

    const addLineBreak = (e, enterKeyCode) => {
        if (!(e.keyCode === enterKeyCode && e.ctrlKey)) return;
        const input = titleRef.current;
        document.execCommand("insertLineBreak", false, "")
        input.focus();
    }

    const fontFill = (color) => {
        setColor(color)
    }

    const titleValidator = (e) => {
        const input = titleRef.current;
        const allowedKeys = ["Backspace"];

        if (input.innerText.length >= maxTitleLen && allowedKeys.indexOf(e.key) === -1) {
            e.preventDefault();
        };
    }

    const handleTitlePaste = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let paste = (e.clipboardData || window.clipboardData).getData('text/plain');

        if (paste.length > maxTitleLen || paste.length + titleRef.current.innerText.length > maxTitleLen) {
            return;
        }

        document.execCommand("insertText", false, paste);
    }

    const handleTitleKeyDown = (e) => {
        const enterKeyCode = 13;
        titleValidator(e);
        addLineBreak(e, enterKeyCode);
    }

    const handleTitleKeyUp = (e) => {
        handleTitleLenCounter(e);
    }

    const handleTitleLenCounter = (e) => {
        if (titleRef.current == null) return;
        setTitleLenCounter(titleRef.current.innerText.length);
    }

    const handleTitleClick = (e) => {
        setIsBold(document.queryCommandState("bold"))
        setIsCursive(document.queryCommandState("italic"));
        setIsUnderline(document.queryCommandState("underline"));
        let forecolor = document.queryCommandValue("forecolor");

        if (forecolor !== "rgb(0, 0, 0)") {
            // show placeholder as real color in option select
            colors.map(color => {
                let placeholder = color.placeholder;
                if (placeholder == null) return;
                let red = parseInt(placeholder[1] + placeholder[2], 16);
                let green = parseInt(placeholder[3] + placeholder[4], 16);
                let blue = parseInt(placeholder[5] + placeholder[6], 16);

                let rgbPlaceholder = `rgb(${red}, ${green}, ${blue})`;
                if (forecolor === rgbPlaceholder) {
                    forecolor = color.color;
                }
            })

            setInitialFontColor(forecolor);
        }
        setInitialColorValue();
    }

    const setInitialColorValue = () => {
        if (title !== "") return;

        let color = colors[0].color;
        setInitialFontColor(color);
        if (colors[0].placeholder !== null) {
            color = colors[0].placeholder;
        }

        document.execCommand("forecolor", false, color);
    }

    const addEmojiToTitle = (emoji) => {
        document.execCommand("insertText", false, emoji);
        setEmoji(null);
    }

    useEffect(() => {
        if (emoji)
            addEmojiToTitle(emoji);
    }, [emoji]);

    return (
        <div className="text-editor">
            <div className="text-editor__input"
                contentEditable
                suppressContentEditableWarning
                onKeyDown={handleTitleKeyDown}
                onKeyUp={handleTitleKeyUp}
                onInput={handleTitleKeyUp}
                onBlur={onChangeTitle}
                onClick={handleTitleClick}
                placeholder="Введите название"
                dangerouslySetInnerHTML={{ __html: sanitizer(getTitle()) }}
                ref={titleRef}
            />
            {titleLenCounter !== 0 && <div className="length-counter">
                <span>{titleLenCounter}</span>
                /
                <span>{maxTitleLen}</span>
            </div>}

            <div className="text-editor__panel">
                <button onMouseDown={bold} className={classNames({ "panel-btn": true, "icon-font-bold": true, "icon-font-bold_active": isBold })} />
                <button onMouseDown={cursive} className={classNames({ "panel-btn": true, "icon-font-cursive": true, "icon-font-cursive_active": isCursive })} />
                <button onMouseDown={underline} className={classNames({ "panel-btn": true, "icon-font-underline": true, "icon-font-underline_active": isUnderline })} />
                <div className="font-size-container">
                    <OptionSelect
                        type="font-size-select"
                        options={fontSizes.map(size => {
                            return {
                                value: size.value,
                                label: size.label,
                            }
                        })}
                        setValue={setFontSizeByValue}
                        selected={{
                            label: eventId === null && initialFontSize === 3 ?
                                <i className="icon-font-size" /> :
                                fontSizes[initialFontSize - 1].label
                        }}
                    />
                </div>
                <OptionSelect
                    type="font-fill-select"
                    options={colors.map(color => {
                        return {
                            value: color.color,
                            label: <i style={{ color: color.color }} className="icon-color" />
                        }
                    })}
                    setValue={fontFill}
                    selected={{
                        label: eventId === null && color === colors[1].color ?
                            <i className="icon-fill-color" /> :
                            <i style={{ color: color }} className="icon-color" />
                    }}
                />
                <OptionSelect
                    type="font-color-select"
                    options={colors.map(color => {
                        return {
                            value: color.color,
                            label: <i style={{ color: color.color }} className="icon-color" />
                        }
                    })}
                    setValue={setFontColor}
                    selected={{
                        label: eventId === null && initialFontColor === colors[0].color ?
                            <i className="icon-font-color" /> :
                            <i style={{ color: initialFontColor }} className="icon-color" />
                    }}
                />
            </div>
        </div>
    );
}


export { TextEditor };
