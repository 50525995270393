import { http } from "./api";

export const addThematics = (thematic) => {
    return http.post(`/thematics`, thematic,
        {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        }
    );
}

export const updateThematic = (thematic) => {
    return http.put(`/thematics/${thematic.id}`, thematic,
        {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        }
    );
}

export const getThematics = () => {
    return http.get(`/thematics`,
        {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        }
    );
}

export const deleteThematic = (id) => {
    return http.delete(`/thematics/${id}`,
        {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        }
    );
}
