import dayjs from "dayjs";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useEventListener from "@use-it/event-listener";

import {
    currentPeriodDays,
    daysForNextPeriod,
    daysForPreviousPeriod,
    getNumberOfDaysInPeriod,
    getWeekDays,
    getWeekNumbers,
    isCurrentWeekAndYear,
    isPastWeek,
    monthWeeksLength,
    periodSplit,
    equalizeRowsByWeeks,
    getPeriodDays
} from "../index";

import { eventsToMap, storeEvents, storeEventsByDates, storeEventsByIds, toEventsByDates, toEventsByIds } from "../../../store/events";

import "./Calendar.scss";
import { getEventsAPI, getCategoriesAPI } from "../../../api";
import { fetchedCategories } from "../../../store/categories";
import { getRightDate } from "../../../functions/serviceFunctions";
import { addEvent, updateEvent, removeEvent } from "../../../store/events";
import store from "../../../store";
import { DayAsTile } from "../Day";
import Grid from "../GridLayout/Grid";

function Calendar({ initialDate, period, setSelectedDate, layoutOnClick, onMonthClick }) {
    const table = useSelector(state => state.settings.theme) === 'table';

    const Skeuomorphism = useSelector(state => state.settings.design) === 'Skeuomorphism';
    const isPeriodQuarterYear = period === 3
    const weekdays = (table && !isPeriodQuarterYear && !Skeuomorphism)
        ? ['ПОНЕД.', 'ВТОРНИК', 'СРЕДА', 'ЧЕТВЕРГ', 'ПЯТНИЦА', 'СУББОТА', 'ВОСКРЕС.']
        : ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
    const themeColor = useSelector(state => state.settings.themeColor);
    const highlightedThematics = useSelector(state => state.thematics.highlightedThematics);

    const dispatch = useDispatch();

    useEffect(() => {
        getEventsAPI().then(res => {
            dispatch(storeEvents(res));
        }).catch(err => console.log(err));

        getCategoriesAPI().then(res => {
            dispatch(fetchedCategories(res.data));
        }).catch(err => console.log(err));
    }, []);

    let thematicDateRange = []
    const selectDateRange = (day) => {
        thematicDateRange.push(day)
    }

    const handleDateRangeSelection = (days) => {
        if (thematicDateRange.length) {
        }

    }
    const periodDaysCount = getPeriodDays(initialDate, period);
    const periodDays = currentPeriodDays(periodDaysCount, getRightDate(initialDate))
    /// For month
    const monthDaysCount = getNumberOfDaysInPeriod(initialDate.date(1), 1);
    const currentMonthDays = currentPeriodDays(monthDaysCount, initialDate.date(1));
    const previousMonthDays = daysForPreviousPeriod(initialDate, currentMonthDays);
    const nextMonthDays = daysForNextPeriod(initialDate, currentMonthDays, period);
    let isThematicFirstDayOutside = false

    const monthDays = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays]
    const days = period === 1 ? [...monthDays] : [...periodDays];
    let periods = periodSplit(period, days);
    periods = periods.map(p => {
        const weekdays = getWeekDays(p)
        const weekNumbers = getWeekNumbers(weekdays)
        return {
            weekdays: weekdays,
            weekNumbers: weekNumbers,
            monthsLength: monthWeeksLength(p.filter(day => day.isCurrentPeriod), weekNumbers, weekdays[0].length)
        }
    });
    const isDateSelection = useSelector(state => state.modal.isDateSelection)
    periods = equalizeRowsByWeeks(periods, period, false);

    const alternate = useSelector(state => state.settings.theme) === 'rounded' && period === 1;
    const showPastDays = useSelector(state => state.settings.showPastDays);
    const [copyKeyPressed, setCopyKeyPressed] = useState(false);
    const [cutKeyPressed, setCutKeyPressed] = useState(false);
    const [dragKeyPressed, setDragKeyPressed] = useState(false);
    const [pasteKeyPressed, setPasteKeyPressed] = useState(false);
    const [escapeKeyPressed, setEscapeKeyPressed] = useState(false);
    const keyPressedHandler = (e) => {
        if (e.key === 'c' || e.key === 'C' || e.key === 'с' || e.key === 'С') {
            setCopyKeyPressed(true);
        } else if (e.key === 'x' || e.key === 'X' || e.key === 'ч' || e.key === 'Ч') {
            setCutKeyPressed(true);
        } else if (e.key === 'v' || e.key === 'V' || e.key === 'м' || e.key === 'М') {
            setPasteKeyPressed(true);
        } else if (e.ctrlKey || e.metaKey) {
            setDragKeyPressed(true)
        } else if (e.key == 'Escape') {
            setEscapeKeyPressed(true)
        }
        else {
            setCopyKeyPressed(false);
            setCutKeyPressed(false);
            setPasteKeyPressed(false);
            setDragKeyPressed(false)
            setEscapeKeyPressed(false)
        }
    };
    if (highlightedThematics != null) {
        days[0].date.isAfter(highlightedThematics?.date_from) ? isThematicFirstDayOutside = true : isThematicFirstDayOutside = false
    }

    useEventListener('keydown', keyPressedHandler);
    useEventListener('keyup', (e) => {
        setCopyKeyPressed(false);
        setCutKeyPressed(false);
        setPasteKeyPressed(false);
        setDragKeyPressed(false)
        setEscapeKeyPressed(false)
    });



    return (<>
        {isThematicFirstDayOutside &&
            <div className="thematic-outside" >
                <span dangerouslySetInnerHTML={{ __html: `Тематика: ${highlightedThematics.name}` }}>
                </span>
                <br />

                <span dangerouslySetInnerHTML={{ __html: `Старт:${dayjs(highlightedThematics.date_from).format('DD-MM-YYYY')}` }}>
                </span>

            </div>
        }
        {
            isDateSelection && <div className="thematic-date-selection"></div>
        }
        {
            Skeuomorphism && period === 1 &&
            <div
                className={classNames({
                    'month-number': true,

                })}
            >
                <span className="month-and-week-number--span">
                    {dayjs(initialDate).locale('ru').format('MMMM').toUpperCase()}
                </span>

                <span className="month-and-week-number--span">
                    {dayjs(initialDate).year()}
                </span>
            </div >
        }

        {periods.map((p, i) => {
            return (

                <table
                    key={i}
                    className={classNames({
                        'table-view': table,
                        'alternate-view': alternate,
                        'modern-view': !table && !alternate,
                        'period-is-month': period === 1,
                        'period-is-half-year': period === 6,
                        'period-is-year': period === 12,
                        'period-is-quarter-year': period === 3,

                    })}>

                    <thead>
                        {period > 1 &&
                            (<tr>
                                <td className={classNames({
                                    'period-is-quarter-year-weekday': isPeriodQuarterYear,
                                    'gray': true,
                                    'max-w-40px': true

                                })}>
                                </td>
                                {p.monthsLength.map((month, index) => {
                                    return (
                                        <td key={index} onClick={() => { onMonthClick(2, month.monthNum, month.year) }}
                                            className={classNames({
                                                'month': true,
                                                'plus-last-week': month.plusLastWeek,
                                                'plus-first-week': month.plusFirstWeek,
                                            })
                                            }
                                            colSpan={month.monthLength}>
                                            <span>{month.date()}</span></td>
                                    )
                                })}
                            </tr>)
                        }
                        <tr style={{ position: 'relative' }}>
                            <td className={classNames({
                                'period-is-quarter-year-weekday': isPeriodQuarterYear,
                                'gray': Skeuomorphism,
                                'shifted-to-top': Skeuomorphism && period !== 1,
                                'w-40px': Skeuomorphism && period !== 1,
                                'w-full': Skeuomorphism && period === 1,
                                'max-w-40px': Skeuomorphism

                            })}>&nbsp;</td>
                            {p.weekNumbers.map((weekNumber, index) => {
                                return <td className={classNames({
                                    'day-of-week-month': true,
                                    'day-of-month--past-week': isPastWeek(weekNumber.date) && showPastDays,
                                    'period-is-month': period === 1,
                                    'period-is-quarter-year': period === 3,
                                    'period-is-third-of-year': period === 4,
                                    'period-is-half-year': period === 6,
                                    'period-is-year': period === 12,
                                    'day-of-month--current-week': isCurrentWeekAndYear(weekNumber.date),
                                    'alternate-weeknumber': alternate,
                                    'table-weeknumber': table,
                                    'blue': themeColor === 'blue' && !alternate,
                                    'another-month-week-left-border': weekNumber.anotherMonthWeekLeft,
                                    'another-month-week-right-border': weekNumber.anotherMonthWeekRight
                                })}
                                    key={index}
                                    onClick={() => layoutOnClick(dayjs(weekNumber.date).week(weekNumber.weekNumber), 1)}>{weekNumber.weekNumber}</td>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(p.weekdays).map(([weekDay, days], index) => {
                            return (
                                <tr key={index}>
                                    <td className={classNames(
                                        {
                                            'day-of-week-month': true,
                                            'day-of-month': period < 3,
                                            'period-is-third-of-year-day': period === 4,
                                            'period-is-quarter-year-day': period === 3,
                                            'period-is-half-year-day': period === 6,
                                            'period-is-year-day': period === 12,
                                            'weekdays': true,
                                            'weekend': +weekDay === 5 || +weekDay === 6,
                                            'max-w-40px': Skeuomorphism,
                                        }
                                    )}>{weekdays[weekDay]}</td>
                                    {isDateSelection && days.map((day, index) => <DayAsTile key={index} onMouseOver={() => handleDateRangeSelection()}
                                        onClick={() => selectDateRange(day.date)}
                                        period={period} day={day}
                                        alternate={alternate}
                                        table={table}
                                        isSameDate={event => dayjs(event.date).isSame(day.date, 'day')}
                                        copyKeyPressed={copyKeyPressed}
                                        cutKeyPressed={copyKeyPressed}
                                        pasteKeyPressed={pasteKeyPressed}
                                        isDateSelection={isDateSelection}
                                        escapeKeyPressed={escapeKeyPressed}
                                        dragKeyPressed={dragKeyPressed}

                                    />
                                    )}

                                    {!isDateSelection && days.map((day, index) => <DayAsTile key={index}
                                        period={period} day={day}
                                        alternate={alternate}
                                        table={table}
                                        isSameDate={event => dayjs(event.date).isSame(day.date, 'day')}
                                        copyKeyPressed={copyKeyPressed}
                                        cutKeyPressed={cutKeyPressed}
                                        pasteKeyPressed={pasteKeyPressed}
                                        dragKeyPressed={dragKeyPressed}
                                        escapeKeyPressed={escapeKeyPressed}
                                    />
                                    )}

                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            )
        })}</>)
}

export default Calendar;