import { useEffect, forwardRef } from "react";

import "./styles.scss"

const Scrollbar = forwardRef(({ containerRef }, ref) => {

    useEffect(() => {
        calculateThumbHeight();
        udpateScrollbarPosition();

        const timer = setTimeout(() => {
            calculateThumbHeight();
            udpateScrollbarPosition();
        }, 1000)

        return () => {
            clearTimeout(timer)
        }
    });

    useEffect(() => {
        if (containerRef && containerRef.current) {
            containerRef.current.addEventListener("scroll", udpateScrollbarPosition);
            return function cleanup() {
                const copyEventsRef = { ...containerRef };
                if (copyEventsRef && copyEventsRef.current) {
                    copyEventsRef.current.removeEventListener("scroll", udpateScrollbarPosition);
                }
            }
        }
    })

    const calculateThumbHeight = () => {
        if (!ref || !ref.current || !containerRef || !containerRef.current) return;
        const events = containerRef.current;
        const thumb = ref.current;
        const height = Math.round(events.offsetHeight / events.scrollHeight * 100);
        thumb.style.height = height + "%";
    }

    const udpateScrollbarPosition = () => {
        if (!ref || !ref.current || !containerRef || !containerRef.current) return;
        const container = containerRef.current;
        const thumb = ref.current;

        let pos = Math.round(container.scrollTop / container.scrollHeight * 100);
        if (pos >= 100 || pos < 0) return;
        thumb.style.top = `${pos}%`;
    }

    return (
        <div className="scrollbar">
            <div className="scrollbar-container">
                <div className="scrollbar__track"></div>
                <div className="scrollbar__thumb" ref={ref}></div>
            </div>
        </div>
    );
})

export default Scrollbar;