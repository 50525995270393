import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getEventsPositionsForDate, updateEvent, getEventById, moveEvent, updateEventsPositions, getEventsForDate } from "../../../store/events";
import { Responsive, WidthProvider } from "react-grid-layout";
import { EventItem } from '../Event';
import dayjs from "dayjs";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './GridAsTile.scss'
import store from '../../../store';
import { moveEventAPI, updateEventsPositionsAPI } from '../../../api/eventAPI';
import { useDrop, useDrag } from 'react-dnd';
import { DraggableTypes } from '../../utility/DraggableTypes';
const ResponsiveGridLayout = WidthProvider(Responsive);

function GridAsTile(props) {
    const DEFAULT_MAX_ROWS = 2;
    const ref = useRef(null);
    const dispatch = useDispatch();
    const date = dayjs(props.date).format('YYYY-MM-DD');
    const eventsForDate = props.events
    const [isResizable, setIsResizable] = useState(false);
    const [isDraggable, setIsDraggable] = useState(false);
    const maxRows = props.maxRows || DEFAULT_MAX_ROWS;

    const eventsIds = useSelector(state => getEventsForDate(state, date));
    const data = eventsIds.map((id) => {
        const event = getEventById(store.getState(), id);
        return {
            i: event.id,
            x: event.pos_x,
            y: event.pos_y,
            w: event.width / 50,
            h: event.height / 50,
            id: event.id,
        }
    })
    const calculateRowHeight = () => {
        if (!props.maxRows) return DEFAULT_MAX_ROWS
        const uniqueYValues = new Set();
        data.forEach((item) => {
            uniqueYValues.add(item.y);
        });
        if (uniqueYValues.has(3)) return 4
        if (uniqueYValues.has(2)) return 3
        const totalEventsHeight = Array.from(uniqueYValues).reduce((acc, y) => {
            const eventsWithSameY = data.filter((item) => item.y === y);

            if (eventsWithSameY.length > 0) {

                return acc + eventsWithSameY[0].h;
            }
            return acc;
        }, 0);




        return Math.min(Math.max(totalEventsHeight, 2), 4)
    };


    useEffect(() => {
        setIsDraggable(props.dragKeyPressed);
    }, [props.dragKeyPressed]);

    const onDrag = () => {
        if (!props.dragKeyPressed) setIsDraggable(false)
    }

    const onDragStop = (layout, oldItem, newItem, placeholder, e, element) => {
        setIsDraggable(false);
        let events = [];
        layout.forEach((item) => {
            const id = Number(item.i);
            if (isNaN(id)) return;
            events.push({
                id: id,
                pos_x: item.x,
                pos_y: item.y,
                width: item.w * 50,
                height: item.h * 50,
            });
        })

        updateEventsPositionsAPI(events).then(() => {
            dispatch(updateEventsPositions(events));
            if (props.dragKeyPressed) setIsDraggable(true);

        }).catch((error) => {
            console.log(error);
            if (props.dragKeyPressed) setIsDraggable(true);
        });
    }

    const countHiddenEvents = () => {
        if (ref.current == null) return;
        const element = ref.current?.elementRef?.current;
        const children = element?.children;

        if (element == null) return;
        if (children == null || children.length == 0)
            return;

        const events = Array.from(children);
        const hiddenEvents = events.filter((event) => {
            const top = event.getBoundingClientRect().top - element.getBoundingClientRect().top;
            return top >= element.offsetHeight;
        })

        props.setExtraEvents(hiddenEvents.length);
    }

    useEffect(() => {
        countHiddenEvents();
        calculateRowHeight();
    }, [data])

    return (

        <ResponsiveGridLayout
            className="layout overflow-hidden"
            layout={data}
            ref={ref}
            cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
            isDraggable={isDraggable}
            onDragStop={onDragStop}
            onDrag={onDrag}
            margin={[1, 1]}
            rowHeight={props.tileHeight / calculateRowHeight()}
            isResizable={false}
            compactType={null}
            style={{ width: '100%', height: '100%', zIndex: 2 }}
            resizeHandle={<div></div>}
        >

            {data.map((item, i) => (
                <div
                    key={item.id}
                    data-grid={item}

                    className="no-transitions grab"
                    style={{ userSelect: 'none' }}
                >
                    <EventItem
                        key={item.id}
                        id={item.id}
                        eventsInDateCount={eventsForDate.length}
                        maxRows={maxRows}
                        copyKeyPressed={props.copyKeyPressed}
                        cutKeyPressed={props.cutKeyPressed}
                        isDragging={!isDraggable}
                    />
                </div>
            ))}
        </ResponsiveGridLayout >

    );
}

export default GridAsTile;
