import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getEventsPositionsForDate, updateEvent, getEventById, getEventsForDate, updateEventsPositions } from "../../../store/events";
import { Responsive, WidthProvider } from "react-grid-layout";
import { EventDetail } from '../Event';
import dayjs from "dayjs";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './Grid.scss'
import { updateEventAPI } from '../../../api';
import store from '../../../store';
import { updateEventsPositionsAPI } from '../../../api/eventAPI';
const ResponsiveGridLayout = WidthProvider(Responsive);

function Grid(props) {
    const dispatch = useDispatch();
    const date = dayjs(props.date).format('YYYY-MM-DD');
    const [isResizable, setIsResizable] = useState(true);
    const [isDraggable, setIsDraggable] = useState(false);
    const [keyPressed, setKeyPressed] = useState(false);

    useEffect(() => {
        const keyDownHandler = (e) => {
            if (e.ctrlKey || e.metaKey) {
                setKeyPressed(true);
                setIsDraggable(true);
            } else {
                setKeyPressed(false);
                setIsDraggable(false);
            }
        };

        const keyUpHandler = () => {
            setKeyPressed(false);
            setIsDraggable(false);
        };

        window.addEventListener('keydown', keyDownHandler);
        window.addEventListener('keyup', keyUpHandler);

        return () => {
            window.removeEventListener('keydown', keyDownHandler);
            window.removeEventListener('keyup', keyUpHandler);
        };
    }, []);


    const events = useSelector(state => getEventsForDate(state, date))

    const data = events.map((id) => {
        const event = getEventById(store.getState(), id);
        return {
            i: event.id,
            x: event.pos_x,
            y: event.pos_y,
            w: event.width / 50,
            h: event.height / 50,
            id: event.id,
        }
    })

    const onDrag = () => {
        if (!keyPressed) setIsDraggable(false)
    }

    const updatePositions = (layout, oldItem, newItem, placeholder, e, element) => {
        setIsDraggable(false);
        let events = [];
        layout.forEach((item) => {
            const id = Number(item.i);
            if (isNaN(id)) return;
            events.push({
                id: id,
                pos_x: item.x,
                pos_y: item.y,
                width: item.w * 50,
                height: item.h * 50,
            });
        })

        updateEventsPositionsAPI(events).then(() => {
            dispatch(updateEventsPositions(events));
            if (keyPressed) setIsDraggable(true);
        }).catch((error) => {
            console.log(error);
            if (keyPressed) setIsDraggable(true);
        });
    }


    return (
        <ResponsiveGridLayout
            className="layout"
            layout={data}
            cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
            rowHeight={(props.dayHeight - 15) / 2}
            isDraggable={isDraggable}
            margin={[5, 5]}
            isResizable={true}
            resizeHandles={[]}
            onDragStop={updatePositions}
            onLayoutChange={onDrag}
            onResizeStop={updatePositions}
            compactType={null}
            style={{ width: '100%' }}
            draggableCancel='.btn '
        >
            {data.map((item, i) => (
                <div key={item.id} data-grid={item} className="block" style={{ border: '1px solid black', position: 'relative', userSelect: 'none' }} onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                    <EventDetail edit={props.edit} id={item.id} />
                </div>
            ))}
        </ResponsiveGridLayout>
    );
}

export default Grid;
