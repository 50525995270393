import { createSlice } from "@reduxjs/toolkit";


export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        theme: 'grid',
        showPastDays: true,
        design: 'Original',
        themeColor: 'green',
        filter: {
            categories: [],
        },
        email: '',
        isCustomInitialDate: false,
        eventsToDelete: [],
        eventDeletion: false,
        isThematicsLayout: false,
    },
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        setShowPastDays: (state, action) => {
            state.showPastDays = action.payload;
        },
        setThemeColor: (state, action) => {
            state.themeColor = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setEventsToDelete: (state, action) => {
            if (action.payload.length <= 0) {
                state.eventsToDelete = []
                return
            }
            const eventId = action.payload;
            if (state.eventsToDelete.includes(eventId)) {
                return {
                    ...state,
                    eventsToDelete: state.eventsToDelete.filter(id => id !== eventId),
                };
            }
            else {
                return {
                    ...state,
                    eventsToDelete: [...state.eventsToDelete, eventId],
                };
            }
        },
        setIsCustomInitialDate: (state, action) => {
            state.isCustomInitialDate = action.payload;
        },
        setEventDeletion: (state, action) => {
            state.eventDeletion = action.payload
        },
        setIsThematicsLayout: (state, action) => {
            state.isThematicsLayout = action.payload
        },
        setDesign: (state, action) => {
            state.design = action.payload
        }


        // setProfileConfig: (state, action) => {
        //     state.theme = action.payload.theme;
        //     state.showPastDays = action.payload.showPastDays;
        //     state.themeColor = action.payload.themeColor;
        // }

    },
});

export const { setTheme, setShowPastDays, setThemeColor, setFilter, setEmail, setEventsToDelete, setEventDeletion, setIsCustomInitialDate, setIsThematicsLayout, setDesign } = settingsSlice.actions;

export default settingsSlice.reducer;