import { useState, useRef } from 'react';
import OutsideClick from '../../../functions/OutsideClick';

import emojigroups from '../../../emojis/emojis.json'


import "./styles.scss"

function EmojiSelect({ addEmoji }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const defaultGroup = Object.keys(emojigroups)[0];
    const [currentGroup, setCurrentnGroup] = useState(defaultGroup);
    const [preview, setPreview] = useState(null);

    OutsideClick(ref, () => setIsOpen(false));

    function toggle(e) {
        e.stopPropagation();
        e.preventDefault();

        setIsOpen(!isOpen);
    }

    function handle(emoji) {
        addEmoji(emoji);
    }

    const emojis = (group) => group.map((emoji, index) => {
        return (
            <button
                className="emoji-btn"
                key={index}
                onMouseDown={(e) => { e.preventDefault(); handle(emoji.emoji); }}
                onMouseOver={(e) => { e.preventDefault(); setPreview(emoji.emoji); }}
            >{emoji.emoji}</button>
        );
    })


    const picker = (
        <div className="emoji-picker" onMouseDown={(e) => { e.preventDefault() }}>
            <div className="emoji-groups">
                {Object.keys(emojigroups).map((group, index) => {
                    return (
                        <button
                            className='emoji-groups-btn emoji-btn'
                            onMouseDown={(e) => { e.preventDefault(); setCurrentnGroup(group); }}
                        >
                            {emojigroups[group][0].emoji}
                        </button>
                    );
                })}
            </div>
            <div className="emoji-group">
                <div className="emoji-group-header">{currentGroup}</div>
                <div className="emoji-group-emojis">
                    {emojis(emojigroups[currentGroup])}
                </div>
            </div>

            <div className="emoji-footer">
                {preview}
            </div>
        </div>
    );

    return (
        <div className="emoji-select" ref={ref}>
            <div className="emoji-header" onMouseDown={toggle}>&#128515;</div>

            <div className="emoji-select-container">
                {isOpen &&
                    picker
                }
            </div>
        </div>
    );
}

export default EmojiSelect;

