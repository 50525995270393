import dayjs from "dayjs";
import React, { useRef, useState } from 'react';
import classNames from "classnames";
import { Link } from "react-router-dom";

import OutsideClick from "../../functions/OutsideClick";
import { useSelector, useDispatch } from "react-redux";
import { setShowPastDays, setTheme, setThemeColor, setFilter, setEventDeletion, setEventsToDelete, setIsCustomInitialDate, setIsThematicsLayout, setDesign } from "../../store/settings";
import { openThematicsModal } from "../../store/modal";
import { setThemeAPI, setThemeColorAPI, setPastDaysAPI } from "../../api";
import { OptionSelect } from "../forms/OptionSelect";
import ToggleSwitch from "../forms/ToggleSwitch";
import Filter from "../forms/Filter";

import IconExit from "./icons/icon-exit.svg";
import IconLogout from "./icons/icon-logout.svg";

import 'dayjs/locale/ru';
import "./styles.scss";
import { setDesignAPI, setIsCustomInitialDateAPI } from "../../api/profileAPI";
import { setHalfYearDate, setYearDate } from "../../store/calendar";
import Grid from "../calendar/GridLayout/Grid";

function Settings(props) {
    dayjs.locale('ru');

    const dispatch = useDispatch();
    const ref = useRef()

    const userId = useSelector(state => state.auth.user);
    const eventDeletion = useSelector(state => state.settings.eventDeletion)
    const savedDesign = useSelector(state => state.settings.theme)
    const savedPastDays = useSelector(state => state.settings.showPastDays)
    const savedInitialDate = useSelector(state => state.calendar.yearDate)
    const savedThemeColor = useSelector(state => state.settings.themeColor)
    const isCustomInitialDate = useSelector(state => state.settings.isCustomInitialDate)
    const isThematicsLayout = useSelector(state => state.settings.isThematicsLayout)
    const design = useSelector(state => state.settings.design)
    const categories = useSelector(state => state.categories.categories)
    const filter = useSelector(state => state.settings.filter)
    const email = useSelector(state => state.settings.email)
    const monthList = [
        { label: dayjs().month(0).format("MMMM"), value: 0 },
        { label: dayjs().month(1).format("MMMM"), value: 1 },
        { label: dayjs().month(2).format("MMMM"), value: 2 },
        { label: dayjs().month(3).format("MMMM"), value: 3 },
        { label: dayjs().month(4).format("MMMM"), value: 4 },
        { label: dayjs().month(5).format("MMMM"), value: 5 },
        { label: dayjs().month(6).format("MMMM"), value: 6 },
        { label: dayjs().month(7).format("MMMM"), value: 7 },
        { label: dayjs().month(8).format("MMMM"), value: 8 },
        { label: dayjs().month(9).format("MMMM"), value: 9 },
        { label: dayjs().month(10).format("MMMM"), value: 10 },
        { label: dayjs().month(11).format("MMMM"), value: 11 },
    ]
    const yearList = [
        { label: dayjs().year(2018).format("YYYY"), value: 2018 },
        { label: dayjs().year(2019).format("YYYY"), value: 2019 },
        { label: dayjs().year(2020).format("YYYY"), value: 2020 },
        { label: dayjs().year(2021).format("YYYY"), value: 2021 },
        { label: dayjs().year(2022).format("YYYY"), value: 2022 },
        { label: dayjs().year(2023).format("YYYY"), value: 2023 },
        { label: dayjs().year(2024).format("YYYY"), value: 2024 },
        { label: dayjs().year(2025).format("YYYY"), value: 2025 },
        { label: dayjs().year(2026).format("YYYY"), value: 2026 },
        { label: dayjs().year(2027).format("YYYY"), value: 2027 },
        { label: dayjs().year(2028).format("YYYY"), value: 2028 },
    ]

    const designList = [
        { label: 'Original', value: 0 },
        { label: 'Skeuomorphism', value: 1 }
    ]

    const openThematicsForm = () => {
        dispatch(openThematicsModal());
        props.close();
    }

    OutsideClick(ref, () => {
        props.close()
    })


    const saveDesign = (e) => {
        setThemeAPI(userId, e.target.value)
            .then(res => {
                dispatch(setTheme(res.data.theme));
            })
            .catch(err => console.log("ERROR", err))
    }

    const saveColor = (e) => {
        setThemeColorAPI(userId, e.target.value)
            .then(res => {
                dispatch(setThemeColor(res.data.theme_color));
            })
            .catch(err => console.log("ERROR", err))

    }

    const saveArePastDaysHidden = (value) => {
        setPastDaysAPI(userId, value)
            .then(res => {
                dispatch(setShowPastDays(res.data.past_days));
            })
            .catch(err => console.log("ERROR", err))

    }

    const saveIsCustomInitialDate = (value) => {
        setIsCustomInitialDateAPI(value)
            .then(res => {
                if (res.status == 200) {
                    dispatch(setIsCustomInitialDate(value));
                    if (res.is_custom_initial_date) {
                        props.saveInitialDate(savedInitialDate.month(), savedInitialDate.year());
                    } else {
                        let initial = dayjs();
                        dispatch(setHalfYearDate(dayjs(`${initial.year()}-${initial.month() + 1}-01`, "YYYY-MM-DD")));
                        dispatch(setYearDate(dayjs(`${initial.year()}-${initial.month() + 1}-01`, "YYYY-MM-DD")));
                    }
                }
            }).catch(err => console.log("ERROR", err))
    }

    const saveInitialMonth = (value) => {
        props.saveInitialDate(value, savedInitialDate.year())
    }

    const saveInitialYear = (value) => {
        props.saveInitialDate(savedInitialDate.month(), value)
    }

    const setThematicsLayout = (value) => {
        dispatch(setIsThematicsLayout(value))
    }
    const saveNewDesign = (value) => {
        const label = designList.find((design) => design.value == value).label
        setDesignAPI(label)
            .then(res => {
                if (res.status == 200) {
                    console.log(res);
                    dispatch(setDesign(label))
                }
            }).catch(err => console.log("ERROR", err))
    }

    const setFilterCategories = (value) => {
        let categories = filter.categories;
        if (filter.categories.indexOf(value) !== -1) {
            categories = categories.filter(category => category !== value)
        } else {
            categories = [...categories, value];
        }
        dispatch(setFilter({ categories: categories }));
    }

    const toggleEventDeletion = (value) => {
        dispatch(setEventDeletion(value))
        if (value == false) {
            dispatch(setEventsToDelete([]))
        }
        props.close()
    }

    return (
        <>
            <div className={classNames({
                "settings-wrapper": true,
                "settings-wrapper_open": props.display,
            })}></div>
            <div
                ref={ref}
                className={classNames({
                    "settings-container": true,
                    "settings-container_open": props.display,
                })}
            >
                <div className="settings-header">
                    <h1>Настройки</h1>
                    <button
                        className="settings-exit"
                        onClick={() => props.close()}
                    >
                        <img src={IconExit} alt="settings-exit" />
                    </button>
                </div>
                <div className="settings-body">
                    <form>
                        {eventDeletion &&
                            <button type="button"
                                className={classNames({
                                    "btn": true,
                                    "btn-delete-events": true
                                })}
                                onClick={() => toggleEventDeletion(false)}
                            >
                                <i className={classNames({
                                    "icon-bin-settings": true,
                                })} />
                                <span>
                                    Отменить удаление

                                </span>
                            </button>
                        }
                        {!eventDeletion &&
                            <button type="button"
                                className={classNames({
                                    "btn": true,
                                    "btn-delete-events": true
                                })}
                                onClick={() => toggleEventDeletion(true)}
                            >
                                <i className={classNames({
                                    "icon-bin-settings": true,
                                })} />
                                <span>
                                    Удалить события

                                </span>
                            </button>
                        }

                        <div className="settings-toggle settings-block">
                            <button type="button" className="btn btn-green btn-new-thematic" onClick={openThematicsForm}>Новая тематика</button>
                        </div>
                        <div className="settings-toggle settings-block">
                            <Link to={'/thematics'} className="link">Открыть список тематик</Link>

                        </div>
                        <p className="settings-title">Задать первый месяц и год</p>
                        <div className="settings-toggle settings-block">
                            <ToggleSwitch
                                label={"Задать"}
                                value={isCustomInitialDate}
                                setValue={saveIsCustomInitialDate}
                            />
                        </div>
                        <div className="settings-toggle settings-block">
                            <p className="settings-title">Выберите дизайн</p>
                            <OptionSelect
                                type="month-select w-full"
                                selected={{ label: design, value: designList.find((design) => design.label == design.value) }}
                                setValue={saveNewDesign}
                                options={designList}
                            />

                        </div>
                        {/* <p className="settings-title">Перейти в режим тематики</p>
                        <div className="settings-toggle settings-block">
                            <ToggleSwitch
                                label={"Перейти"}
                                value={isThematicsLayout}
                                setValue={setThematicsLayout}
                            />
                        </div> */}

                        {isCustomInitialDate &&
                            <>
                                <div className="row-between">
                                    <p className="settings-title">Первый месяц</p>

                                    <p className="settings-title">Первый год</p>

                                </div>
                                <div className="row gap-2">
                                    <div className="settings-dropdown settings-block w-full">
                                        <OptionSelect
                                            type="month-select"
                                            selected={{ label: dayjs().month(savedInitialDate.month()).format('MMMM'), value: savedInitialDate.month() }}
                                            setValue={saveInitialMonth}
                                            options={monthList}
                                        />
                                    </div>

                                    <div className="settings-dropdown settings-block w-full">
                                        <OptionSelect
                                            type="month-select"
                                            selected={{ label: dayjs().year(savedInitialDate.year()).format('YYYY'), value: savedInitialDate.year() }}
                                            setValue={saveInitialYear}
                                            options={yearList}
                                        />
                                    </div>

                                </div>

                            </>
                        }

                        <p className="settings-title">Прошедшие даты</p>
                        <div className="settings-toggle settings-block">
                            <ToggleSwitch
                                label={"Затемнять"}
                                value={savedPastDays}
                                setValue={saveArePastDaysHidden}
                            />
                        </div>


                        <p className="settings-title">Фильтр</p>
                        <div className="settings-block">
                            <Filter values={categories} selectedValues={filter.categories} filter={setFilterCategories} clearFilter={() => dispatch(setFilter({ categories: [] }))} />
                        </div>
                        <div style={{ display: "none" }}>

                            <p className="settings-title">Таблица</p>
                            <div className="settings-block">
                                <div className="settings-radio" >
                                    <label htmlFor="standard-design" className="content">
                                        <input type="radio" id="standard-design" name="design" value="grid"
                                            checked={savedDesign === 'grid'} onChange={saveDesign} /> Сетка</label>
                                </div>
                                <div className="settings-radio">
                                    <label htmlFor="alternate" className="content">
                                        <input type="radio" id="alternate" name="design" value="rounded"
                                            checked={savedDesign === 'rounded'} onChange={saveDesign} /> Скругленный</label>
                                </div>
                                <div className="settings-radio">
                                    <label htmlFor="table-view" className="content">
                                        <input type="radio" id="table-view" name="design" value="table"
                                            checked={savedDesign === 'table'} onChange={saveDesign} /> Табличный</label>
                                </div>
                            </div>
                        </div>

                        <p className="settings-title">Цвет</p>
                        <div className="settings-block">
                            <div className="settings-radio">
                                <label htmlFor="green" className="content">
                                    <input type="radio" id="green" name="color" value="green"
                                        checked={savedThemeColor === 'green'} onChange={saveColor} /> Зеленый</label>
                            </div>
                            <div className="settings-radio">
                                <label htmlFor="blue" className="content">
                                    <input type="radio" id="blue" name="color" value="blue"
                                        checked={savedThemeColor === 'blue'} onChange={saveColor} /> Синий</label>
                            </div>
                        </div>
                        <div className="settings-block">
                            <p className="settings-title">Почта</p>
                            <input type="text" className="settings-email-input" value={email} disabled={true} />
                            <Link to={"/activate"}>
                                <button type="button" className="settings-activate">
                                    <span className="settings-activate-text">Смена почты</span>
                                </button>
                            </Link>
                        </div>
                        <Link to={"/logout"}>
                            <button type="button" className="settings-logout">
                                <img src={IconLogout} alt="logout" />
                                <span className="settings-logout-text">Выход</span>
                            </button>
                        </Link>
                    </form>
                </div>
            </div>
        </>
    );
}

export { Settings };