import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { openThematicsModal } from "../../../store/modal";
import { getProfileDataAPI } from "../../../api";
import { getThematics } from "../../../api/thematics";
import { fetchThematics } from "../../../store/thematics";
import { setLoading } from "../../../store/auth";
import { Spinner } from "../../utility/Spinner";
import dayjs from "dayjs";
import './ThematicsList.scss'
import { Link } from "react-router-dom";

function ThematicsList() {
    useEffect(() => {
        dispatch(setLoading(true));
        getProfileDataAPI(localStorage.getItem("user"))
            .then(profileData => {
                dispatch(setLoading(false));
            })
            .catch(err => console.log(err));


        getThematics().then(res => {
            if (res.status === 200) {
                dispatch(fetchThematics(res.data))
            }
        }).catch(err => console.log(err));
    }, []);
    const loading = useSelector(state => state.auth.loading);

    const thematics = useSelector(state => state.thematics.thematics)
    const [uniqueYearsSet, setUniqueYearsSet] = useState(new Set());
    const dispatch = useDispatch();
    const add = (year) => {
        const firstDayOfYear = `1-1-${year}`
        dispatch(openThematicsModal({ date: firstDayOfYear }));
    }
    var isBetween = require('dayjs/plugin/isBetween')
    dayjs.extend(isBetween)
    const edit = (value) => {
        dispatch(openThematicsModal({ thematic: value }));

    }
    useEffect(() => {
        const newSet = new Set();
        thematics.forEach(thematic => {
            const year = dayjs(thematic.date_from).year();
            newSet.add(year);
        });
        const sortedArray = Array.from(newSet).sort((a, b) => { return a - b });
        const minYear = Math.min(...sortedArray);
        const maxYear = Math.max(...sortedArray);

        // Generate years from the minimum to the maximum
        const generatedYears = [];
        for (let year = minYear; year <= maxYear; year++) {
            generatedYears.push(year);
        }

        // Add three more years after the maximum year
        if (generatedYears.length) {
            for (let i = 1; i <= 3; i++) {
                generatedYears.push(maxYear + i);
            }
        }

        setUniqueYearsSet(generatedYears);

    }, [thematics]);

    return loading ? (
        <Spinner />
    ) : (
        <section className="thematics-layout">
            <Link to={'/'} className="link">Вернуться в календарь</Link>
            <h1 className="thematics-layout-header">Тематика</h1>
            <div className="thematics-layout-body">
                {Array.from(uniqueYearsSet).map((year) => (
                    <div key={year} className="thematics-container">
                        <span dangerouslySetInnerHTML={{ __html: year }}></span>
                        <div className="thematics" style={{ marginTop: '10px' }}>

                            {thematics
                                .filter((thematic) => dayjs(thematic.date_from).year() === year)
                                .sort((a, b) => dayjs(a.date_from) - dayjs(b.date_from))
                                .map((thematic) => (
                                    <button
                                        onClick={() => { edit(thematic) }}
                                        className="thematic-button"
                                        key={thematic.id}
                                        style={{
                                            backgroundColor: `${thematic.color}80`,
                                            border: `1px solid ${thematic.color == '#FFFFFF' ? 'gray' : thematic.color}`,
                                            borderRadius: '3px',
                                            padding: '8px',
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <div className='existing-thematic'>
                                            <span style={{ color: thematic.color == '#FFFFFF' || thematic.color == '#F5E663' ? 'black' : thematic.color, fontFamily: 'Roboto', fontSize: '16px', overflow: 'hidden', fontWeight: '500', marginRight: '6px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{thematic.name}</span>
                                            <div className='existing-thematic-dates'>
                                                <span style={{ fontFamily: 'Roboto', fontSize: '16px', color: '#535757' }}>({dayjs(thematic.date_from).format('DD.MM.YYYY')}</span>
                                                <span style={{ fontFamily: 'Roboto', fontSize: '16px', color: '#535757' }}> - {dayjs(thematic.date_to).format('DD.MM.YYYY')}  )</span>
                                            </div>
                                        </div>
                                        <div />
                                    </button>
                                ))}
                        </div>
                        <button type="button" className="add-thematic" onClick={() => { add(year) }}>+ Добавить</button>
                    </div>
                ))}
            </div>
        </section >
    );
}

export { ThematicsList };
