import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";


export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    dayDate: dayjs(),
    weekDate: dayjs(),
    monthDate: dayjs(),
    quarterYearDate: dayjs(),
    halfYearDate: dayjs(),
    yearDate: dayjs(),
    layout: 2
  },
  reducers: {
    setDayDate: (state, action) => {
      state.dayDate = action.payload;
    },
    setWeekDate: (state, action) => {
      state.weekDate = action.payload;
    },
    setMonthDate: (state, action) => {
      state.monthDate = action.payload;
    },
    setQuarterYearDate: (state, action) => {
      state.quarterYearDate = action.payload;
    },
    setHalfYearDate: (state, action) => {
      state.halfYearDate = action.payload;
    },
    setYearDate: (state, action) => {
      state.yearDate = action.payload;
    },
    setLayout: (state, action) => {
      state.layout = action.payload;
    }
  },
});

export const { setDayDate, setWeekDate, setMonthDate, setHalfYearDate, setQuarterYearDate, setYearDate, setLayout } = calendarSlice.actions;

export default calendarSlice.reducer;