import dayjs from "dayjs";
import useEventListener from "@use-it/event-listener";
import { useSelector } from "react-redux";
import { getYearList } from "../../../functions/serviceFunctions";
import ArrowLeft from '../../../icons/icon-arrow-left.svg';
import ArrowRight from '../../../icons/icon-arrow-right.svg';
import DatePlus from '../../../icons/redesign/date-plus.svg';
import DateMinus from '../../../icons/redesign/date-minus.svg';
import { OptionSelect } from "../../forms/OptionSelect";


import 'dayjs/locale/ru';
import './styles.scss';
import classNames from "classnames";


function DateSwitcher({ saveInitialDate, setDate, date, type }) {
    const DAY = 'day';
    const WEEK = 'week';
    const MONTH = 'month';
    const YEAR = 'year';
    const HALF_YEAR = 'half-year';
    const THIRD_OF_YEAR = 'third-of-year';
    const QUARTER_YEAR = 'quarter-year';
    const design = useSelector(state => state.settings.design)
    const themeColor = useSelector(state => state.settings.themeColor);
    const alternate = useSelector(state => state.settings.theme) === 'rounded';

    const layoutTypes = {
        day: 0,
        week: 1,
        month: 2,
        year: 4,
        'half-year': 3,
        'quarter-year': 5,

    }
    const yearList = getYearList().map(year => {
        return {
            value: year,
            label: year
        }
    });

    const minusDate = () => {
        switch (type) {
            case DAY:
                setDate(dayjs(date).subtract(1, 'day'), 0);
                break;
            case WEEK:
                setDate(dayjs(date).subtract(1, 'week'), 1);
                break;
            case MONTH:
                setDate(dayjs(date).subtract(1, 'month'), 2);
                break;
            case YEAR:
                setDate(dayjs(date).subtract(1, 'month'), 4);
                break;
            case HALF_YEAR:
                setDate(dayjs(date).subtract(1, 'month'), 3);
                break;
            case THIRD_OF_YEAR:
                setDate(dayjs(date).subtract(1, 'month'));
                break;
            case QUARTER_YEAR:
                setDate(dayjs(date).subtract(1, 'month'), layoutTypes[type]);
                break;
            default:
                break;
        }
    };

    const plusDate = () => {
        switch (type) {
            case DAY:
                setDate(dayjs(date).add(1, 'day'), 0);
                break;
            case WEEK:
                setDate(dayjs(date).add(1, 'week'), 1);
                break;
            case MONTH:
                setDate(dayjs(date).add(1, 'month'), 2);
                break;
            case YEAR:
                setDate(dayjs(date).add(1, 'month'), 4);
                break;
            case HALF_YEAR:
                setDate(dayjs(date).add(1, 'month'), 3);
                break;
            case THIRD_OF_YEAR:
                setDate(dayjs(date).add(1, 'month'));
                break;
            case QUARTER_YEAR:
                setDate(dayjs(date).add(1, 'month'), layoutTypes[type]);
                break;
            default:
                break;
        }
    };

    const setDateFromOptions = (selected) => {
        //TO DO: Check if february 29 and chosen year is leap
        setDate(dayjs(date).set('year', selected), layoutTypes[type]);

    };

    const todayDate = () => {
        setDate(dayjs(), layoutTypes[type]);
        saveInitialDate(dayjs().month(), dayjs().year())
    }

    const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const wheelHandler = (e) => {
        if (e.shiftKey || e.metaKey) {
            if (e.deltaY > 0) {
                plusDate();
            } else if (e.deltaY < 0) {
                minusDate();
            }
        }
    }

    useEventListener('wheel', wheelHandler);

    return (
        <div className={classNames({
            'switcher': true,
            'switcher-period-is-week': design === 'Skeuomorphism' && type === WEEK,

        })}>
            <div className="switcher__item switcher__item--previous" onClick={minusDate}>

                <input type="image" src={design == 'Skeuomorphism' ? DateMinus : ArrowLeft} alt="previous" />
            </div>
            <div className={classNames({
                "switcher__item": true,
                "switcher__item--today": true,
                "bold": true,
                "blue-gradient": themeColor === 'blue' && !alternate

            })} onClick={todayDate}>
                {
                    design == 'Skeuomorphism' ? <span className={classNames({
                        "gradient-text": true,
                    })} >СЕГОДНЯ</span> : `СЕГОДНЯ`
                }

            </div>
            <div className="switcher__item switcher__item--next" onClick={plusDate}>
                <input type="image" src={design == 'Skeuomorphism' ? DatePlus : ArrowRight} alt="next" />
            </div>

            {type === WEEK &&
                <div className="switcher__item">
                    <span className="switcher__item-text h-1 bold">{dayjs(date).week()} </span>
                    <span className="switcher__item-text h-1">неделя {dayjs(date).locale('ru').format('YYYY')}</span>
                </div>
            }
            {type === MONTH &&
                <div className="switcher__item">
                    <span className="switcher__item-text h-1 bold">{capitalize(dayjs(date).locale('ru').format('MMMM'))} </span>
                    <span className="switcher__item-text h-1">
                        <OptionSelect type="year-select" selected={{ label: dayjs(date).locale('ru').format('YYYY'), value: dayjs(date).locale('ru').format('YYYY') }} setValue={setDateFromOptions} options={yearList} />
                    </span>
                </div>
            }
            {type === QUARTER_YEAR &&
                <div className="switcher__item">
                    <span className="switcher__item-text h-1 bold">{capitalize(dayjs(date).locale('ru').format('MMMM'))} </span>
                    <span className="switcher__item-text h-1">{dayjs(date).locale('ru').format('YYYY')}</span>
                    <span className="switcher__item-text h-1"> — </span>
                    <span className="switcher__item-text h-1 bold">{capitalize(dayjs(date).locale('ru').add(2, 'month').format('MMMM'))} </span>
                    <span className="switcher__item-text h-1">{dayjs(date).locale('ru').add(2, 'month').format('YYYY')}</span>
                </div>
            }
            {type === THIRD_OF_YEAR &&
                <div className="switcher__item">
                    <span className="switcher__item-text h-1 bold">{capitalize(dayjs(date).locale('ru').format('MMMM'))} </span>
                    <span className="switcher__item-text h-1">{dayjs(date).locale('ru').format('YYYY')}</span>
                    <span className="switcher__item-text h-1"> — </span>
                    <span className="switcher__item-text h-1 bold">{capitalize(dayjs(date).locale('ru').add(3, 'month').format('MMMM'))} </span>
                    <span className="switcher__item-text h-1">{dayjs(date).locale('ru').add(3, 'month').format('YYYY')}</span>
                </div>
            }
            {type === HALF_YEAR &&
                <div className="switcher__item">
                    <span className="switcher__item-text h-1 bold">{capitalize(dayjs(date).locale('ru').format('MMMM'))} </span>
                    <span className="switcher__item-text h-1">
                        <OptionSelect type="year-select" selected={{ label: date.locale('ru').format('YYYY'), value: date.locale('ru').format('YYYY') }} setValue={setDateFromOptions} options={yearList} />
                    </span>
                    <span className="switcher__item-text h-1"> — </span>
                    <span className="switcher__item-text h-1 bold">{capitalize(dayjs(date).locale('ru').add(5, 'month').format('MMMM'))} </span>
                    <span className="switcher__item-text h-1">{dayjs(date).locale('ru').add(5, 'month').format('YYYY')}</span>
                </div>
            }
            {type === YEAR &&
                <div className="switcher__item">
                    <span className="switcher__item-text h-1">
                        <OptionSelect type="year-select" selected={{ label: date.locale('ru').format('YYYY'), value: date.locale('ru').format('YYYY') }} setValue={setDateFromOptions} options={yearList} />
                    </span>
                </div>
            }
            {type === DAY &&
                <div className="switcher__item">
                    <span dangerouslySetInnerHTML={{ __html: dayjs(date).locale('ru').format('[<span class="switcher__month-text h-1">]MMMM[</span>] [<span class="switcher__year-text h-1">]YYYY[</span>]') }} />
                </div>
            }
        </div>
    )
}

export { DateSwitcher };