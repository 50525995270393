export const getToken = () => {
    return localStorage.getItem('token');
}
export const removeToken = () => {
    localStorage.removeItem('token');
}
export const setToken = (val) => {
    localStorage.setItem('token', val);
}
export const setUser = (id) => {
    localStorage.setItem('user', id)
}
export const removeUser = () => {
    localStorage.removeItem('user')
}
export const setRefreshToken = (val) => {
    localStorage.setItem('refreshToken', val);
}