import { useDispatch, useSelector } from "react-redux";
import { useCallback, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import { DraggableTypes } from "../../utility/DraggableTypes";
import { closeModal } from "../../../store/modal";
import { vw, vh } from "../../../functions/convert";
import Draggable from "react-draggable";
import "./styles.scss";
import { Thematics } from "../Thematics";
import { DayAsWindow } from "../../calendar/Day";

function Modal() {
    const dispatch = useDispatch();
    const modalProps = useSelector(state => state.modal);
    const isThematicsOpen = useSelector(state => state.modal.isThematicsOpen);
    const dateFrom = useSelector(state => state.modal.date)
    const thematic = useSelector(state => state.modal.thematic)
    const defaultPos = {
        left: window.innerWidth / 2 - vw(50) / 2,
        top: window.innerHeight / 2 - vh(80) / 2,
    };
    const [positions, setPositions] = useState(defaultPos);

    const move = useCallback((left, top) => {
        setPositions({
            left: left,
            top: top
        });
    }, [setPositions]);

    const [, dropRef] = useDrop(() => ({
        accept: DraggableTypes.MODAL,
        drop: (item, monitor) => {
            const delta = monitor.getDifferenceFromInitialOffset()
            const left = Math.round(item.left + delta.x)
            const top = Math.round(item.top + delta.y)
            move(left, top)

            return undefined
        },
    }), [move]);

    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: DraggableTypes.MODAL,
        item: { left: positions.left, top: positions.top },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [positions.left, positions.top]);

    const close = () => {
        dispatch(closeModal());
        setPositions(defaultPos);
    };

    const style = {
        position: 'absolute',
        cursor: 'move',
    }
    if (!modalProps.isOpen) {
        return null;
    }
    return (
        <Draggable cancel=".day-layout-events, .text-editor, .event-description, .category, .thematics-form-block">
            <div className={'modal'}>
                {isThematicsOpen && <Thematics close={close} date={dateFrom} thematic={thematic} />}
                {!isThematicsOpen &&
                    <DayAsWindow
                        date={modalProps.date}
                        onClose={close}
                        updateID={modalProps.updateID}
                        isUpdate={modalProps.updateID !== null}
                        add={modalProps.isNew}
                        thematics={modalProps.thematics}
                    />
                }
            </div>
        </Draggable>

    )
}

export { Modal };