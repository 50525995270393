import { useState } from 'react';
import classNames from 'classnames';

import "./styles.scss";

function SearchBar({ options, value, placeholder, classnames, setValue, handleOptionEdit, handleOptionDelete }) {
    const [isOpen, setIsOpen] = useState(false);

    const onOptionClicked = (e, value) => {
        e.preventDefault();
        e.stopPropagation();
        setValue(value);
        setIsOpen(false);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(true);

        const name = e.target.value;
        const valueInEditMode = value.id != null && value.isEditable;

        let newValue;

        if (valueInEditMode) {
            newValue = {
                id: value.id,
                name: name,
                isEditable: true,
            }
        } else {
            newValue = options.find(option => option.name.toLowerCase() === name.toLowerCase())
            if (!newValue)
                newValue = { id: null, name: name };
        }

        setValue(newValue);
    }

    const makeOptionEditable = (option) => {
        option.isEditable = true;
        setValue(option);
    }

    const handleBlur = () => {
        setValue(value);
        handleOptionEdit(value);
        value.isEditable = false;
        setIsOpen(false);
    }

    return (
        <div className={classNames("searchbar", classnames)}>
            <input
                className="searchbar-input"
                onFocus={() => setIsOpen(true)}
                onBlur={handleBlur}
                value={value.name}
                onChange={handleSearch}
                placeholder={placeholder}
            />

            {
                isOpen && (
                    <div className="searchbar-options">
                        {options.filter(option => option.name.toLowerCase().includes(value.name.toLowerCase())).map((option) => (
                            <li className="searchbar-option"
                                key={option.id}
                                onMouseDown={(e) => onOptionClicked(e, option)}
                            >
                                {option.name}
                                <button
                                    className="option-btn icon-bin"
                                    onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); handleOptionDelete(option); }}
                                />
                                <button
                                    className="option-btn icon-edit"
                                    onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); makeOptionEditable(option); }}
                                />
                            </li>
                        ))}
                    </div>
                )
            }
        </div >
    );
}

export default SearchBar;