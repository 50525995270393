import RangeSlider from "../../forms/RangeSlider";
import { useSelector } from "react-redux";
import dayLayout from "./icons/day-layout.svg";
import dayLayoutFilled from "./icons/day-layout-filled.svg";
import weekLayout from "./icons/week-layout.svg";
import weekLayoutFilled from "./icons/week-layout-filled.svg";
import quarterYearLayout from "./icons/quarter-year.svg"
import quarterYearLayoutFilled from "./icons/quarter-year-filled.svg"
import monthLayout from "./icons/month-layout.svg";
import monthLayoutFilled from "./icons/month-layout-filled.svg";
import sixMonthsLayout from "./icons/six-months-layout.svg";
import sixMonthsLayoutFilled from "./icons/six-months-layout-filled.svg";
import yearLayout from "./icons/year-layout.svg";
import yearLayoutFilled from "./icons/year-layout-filled.svg";

import "./styles.scss";
import classNames from "classnames";

function LayoutSwitcher({ layout, saveLayout }) {
    const design = useSelector(state => state.settings.design)
    const themeColor = useSelector(state => state.settings.themeColor);


    const layouts = {
        0: "День",
        1: "Неделя",
        2: "Месяц",
        3: "Шесть месяцев",
        4: "Год",
        5: 'Три месяца'
    }

    const layoutsByMonthsAmount = {
        2: "1",
        3: "6",
        4: "12",
        5: '3',
        1: 'НЕД'
    }
    const getSortedLayouts = () => {
        return Object.entries(layoutsByMonthsAmount).sort((a, b) => { if (a[1] == 'НЕД') return 1; if (b[1] == 'НЕД') return -1; return b[1] - a[1] })
    }

    const handleChange = (e) => {
        const value = parseInt(e.target.value)
        saveLayout(value);
    }

    return (

        <>
            {
                design == 'Skeuomorphism' ?
                    <div className="layout-switcher">
                        <div className="layout-switcher-btns" >
                            {
                                getSortedLayouts().map(([key, value]) => (
                                    <button
                                        className={classNames({
                                            "layout-switcher-btn": true,
                                            "blue-gradient": themeColor == 'blue',
                                            "green-gradient": themeColor == 'green',
                                            "layout-switcher-btn--active": layout == key

                                        })}
                                        onClick={() => saveLayout(parseInt(key))}
                                        key={key}
                                    >
                                        <span className="layout-switcher-btn-text">{value}</span>
                                    </button>
                                ))
                            }
                        </div >

                    </div >
                    :
                    <div className="layout-switcher">
                        < div className="layout-switcher-btns" >
                            <button className="layout-switcher-btn day-layout-btn" onClick={() => saveLayout(0)}><img src={layout == 0 ? dayLayoutFilled : dayLayout} alt="day-layout" /></button>
                            <button className="layout-switcher-btn week-layout-btn" onClick={() => saveLayout(1)}><img src={layout == 1 ? weekLayoutFilled : weekLayout} alt="week-layout" /></button>
                            <button className="layout-switcher-btn month-layout-btn" onClick={() => saveLayout(2)}><img src={layout == 2 ? monthLayoutFilled : monthLayout} alt="month-layout" /></button>
                            <button className="layout-switcher-btn quarter-year-layout-btn" onClick={() => saveLayout(5)}><img src={layout == 5 ? quarterYearLayoutFilled : quarterYearLayout} alt="quarter-year-layout" /></button>
                            <button className="layout-switcher-btn six-months-layout-btn" onClick={() => saveLayout(3)}><img src={layout == 3 ? sixMonthsLayoutFilled : sixMonthsLayout} alt="six-months-layout" /></button>
                            <button className="layout-switcher-btn year-layout-btn" onClick={() => saveLayout(4)}><img src={layout == 4 ? yearLayoutFilled : yearLayout} alt="year-layout" /></button>
                        </div >
                        <div>
                            {layouts[layout]}
                        </div>
                    </div >

            }
        </>
    )
}

export { LayoutSwitcher };