import dayjs from "dayjs";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Week } from "../Week";
import WeekSkeuomorphism from "../Week/skeuomorphism";
import { DateSwitcher } from "../../utility/DateSwitcher"
import CalendarLayout from "../Calendar/Calendar";
import { LayoutSwitcher } from "../../utility/LayoutSwitcher";
import { Settings } from "../../Settings";
import { setTheme, setShowPastDays, setThemeColor, setEmail, setEventsToDelete, setIsCustomInitialDate, setDesign } from "../../../store/settings";
import { deleteEventsAPI, getProfileDataAPI, setFirstMonthAPI, setLayoutAPI, setProfileDataAPI } from "../../../api";
import { openModal } from "../../../store/modal";
import { setLoading } from "../../../store/auth";
import { setDayDate, setWeekDate, setMonthDate, setHalfYearDate, setYearDate, setLayout, setQuarterYearDate } from "../../../store/calendar";
import { Spinner } from "../../utility/Spinner";
import { setEventDeletion } from "../../../store/settings";
import { removeEvents } from "../../../store/events";
import { fetchThematics } from "../../../store/thematics";
import IconSettings from "./icons/icon-settings.svg"
import BurgerSettings from "../../../icons/redesign/settings.svg"
import './styles.scss'
import { getUser, setFirstYearAPI, setInitialDate } from "../../../api/profileAPI";
import { getThematics, getThematicsAPI } from "../../../api/thematics";
import ThematicsLayout from "../Thematics";
import { DayAsWindow } from "../Day/DayAsWindow";

function CalendarApp() {
    const dispatch = useDispatch();
    const layoutDates = [
        useSelector(state => state.calendar.dayDate),
        useSelector(state => state.calendar.weekDate),
        useSelector(state => state.calendar.monthDate),
        useSelector(state => state.calendar.halfYearDate),
        useSelector(state => state.calendar.yearDate),
        useSelector(state => state.calendar.quarterYearDate),

    ];
    const theme = useSelector(state => state.settings.theme);
    const themeColor = useSelector(state => state.settings.themeColor);
    const showPastDays = useSelector(state => state.settings.showPastDays);
    const layout = useSelector(state => state.calendar.layout);
    const isThematicsLayout = useSelector(state => state.settings.isThematicsLayout)
    const design = useSelector(state => state.settings.design)
    const userId = useSelector(state => state.auth.user);
    const eventDeletion = useSelector(state => state.settings.eventDeletion)
    const eventsToDelete = useSelector(state => state.settings.eventsToDelete)
    const [isDeletionConfirmed, setIsDeletionConfirmed] = useState(false)
    // const dayDate = useSelector(state => state.calendar.dayDate);
    // const weekDate = useSelector(state => state.calendar.weekDate);
    // const monthDate = useSelector(state => state.calendar.monthDate);
    // const halfYearDate = useSelector(state => state.calendar.halfYearDate);
    // const yearDate = useSelector(state => state.calendar.yearDate);
    const loading = useSelector(state => state.auth.loading);
    const layouts = [
        { label: 'day', period: 1 },
        { label: 'week', period: 7 },
        { label: 'month', period: 1 },
        { label: 'half-year', period: 6 },
        { label: 'year', period: 12 },
        { label: 'quarter-year', period: 3 },

    ];
    const initialDate = layoutDates[layout];
    const [date, setDate] = useState(initialDate);
    const [displaySettings, setDisplaySettings] = useState(false);
    const setSelectedDate = (date) => {
        dispatch(openModal({
            date: date,
            updateID: null,
            isNew: false
        }));
    };


    const cancelEventDeletion = () => {
        dispatch(setEventDeletion(false))
        dispatch(setEventsToDelete([]))
    }

    const alertEventDeletion = () => {
        setIsDeletionConfirmed(true)
    }

    const deleteSelectedEvents = () => {
        if (eventsToDelete.length === 0) return;
        deleteEventsAPI(eventsToDelete)
            .then(
                res => {
                    dispatch(removeEvents(eventsToDelete));
                }
            )
            .catch(err => console.log("ERROR", err));
        cancelEventDeletion();
    }

    const layoutOnClick = (date, layout) => {// TODO: Divide state of day and week
        saveInitialDateToStore(date, layout);
        saveLayout(layout);
    }

    //Set starting month for year and half-year layouts
    const saveInitialDate = (month, year = 2023) => {
        setInitialDate(month + 1, year).then(res => {
            dispatch(setQuarterYearDate(dayjs(`${res.data.first_year}-${res.data.first_month}-01`, "YYYY-MM-DD")));
            dispatch(setHalfYearDate(dayjs(`${res.data.first_year}-${res.data.first_month}-01`, "YYYY-MM-DD")));
            dispatch(setYearDate(dayjs(`${res.data.first_year}-${res.data.first_month}-01`, "YYYY-MM-DD")));
        })
    }

    const onMonthClick = (layout, month, year) => {
        setProfileDataAPI(userId, {
            "theme": theme,
            "past_days": showPastDays,
            "theme_color": themeColor,
            "first_month": month + 1,
            "current_view": layout,
            "design": design
        })
            .then(res => {
                dispatch(setLayout(res.data.current_view));
                dispatch(setMonthDate(dayjs(`${year}-${res.data.first_month}-01`, "YYYY-MM-DD")));
                dispatch(setYearDate(dayjs(`${year}-${res.data.first_month}-01`, "YYYY-MM-DD")));
                dispatch(setQuarterYearDate(dayjs(`${year}-${res.data.first_month}-01`, "YYYY-MM-DD")));
                dispatch(setHalfYearDate(dayjs(`${dayjs().year()}-${res.data.first_month}-01`, "YYYY-MM-DD")));
            })
            .catch(err => console.log("ERROR", err))

    }

    useEffect(() => {
        dispatch(setLoading(true));
        getProfileDataAPI(localStorage.getItem("user"))
            .then(profileData => {
                dispatch(setTheme(profileData.theme));
                dispatch(setThemeColor(profileData.theme_color));
                dispatch(setDesign(profileData.design ? profileData.design : 'Original'));
                dispatch(setShowPastDays(profileData.past_days));
                dispatch(setIsCustomInitialDate(profileData.is_custom_initial_date));
                if (profileData.is_custom_initial_date) {
                    dispatch(setQuarterYearDate(dayjs(`${profileData.first_year}-${profileData.first_month}-01`, "YYYY-MM-DD")));
                    dispatch(setHalfYearDate(dayjs(`${profileData.first_year}-${profileData.first_month}-01`, "YYYY-MM-DD")));
                    dispatch(setYearDate(dayjs(`${profileData.first_year}-${profileData.first_month}-01`, "YYYY-MM-DD")));
                } else {
                    let initial = dayjs();
                    dispatch(setQuarterYearDate(dayjs(`${initial.year()}-${initial.month() + 1}-01`, "YYYY-MM-DD")));
                    dispatch(setHalfYearDate(dayjs(`${initial.year()}-${initial.month() + 1}-01`, "YYYY-MM-DD")));
                    dispatch(setYearDate(dayjs(`${initial.year()}-${initial.month() + 1}-01`, "YYYY-MM-DD")));
                }

                dispatch(setLayout(profileData.current_view));
                dispatch(setLoading(false));
            })
            .catch(err => console.log(err));

        getUser().then(res => {
            if (res.status === 200 && res.data.email) {
                dispatch(setEmail(res.data.email))
            }
        }).catch(err => console.log(err));

        getThematics().then(res => {
            if (res.status === 200) {
                dispatch(fetchThematics(res.data))
            }
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        setDate(initialDate);
    }, [initialDate]);

    const saveInitialDateToStore = (date, layout) => {

        switch (layout) {
            case 0:
                dispatch(setDayDate(date));
                break;
            case 1:
                dispatch(setWeekDate(date));
                break;
            case 2:
                dispatch(setMonthDate(date));
                break;
            case 3:
                dispatch(setHalfYearDate(date));
                break;
            case 4:
                dispatch(setYearDate(date));
                break;
            case 5:
                dispatch(setQuarterYearDate(date));
                break;
            default:
                break;

        };
    }

    const saveLayout = (layout) => {

        setLayoutAPI(userId, layout)
            .then(res => dispatch(setLayout(res.data.current_view)))
            .catch(err => console.log(err));
    }

    return loading ? (
        <Spinner />
    ) : (
        <div className={classNames({
            "calendar": true,
            [design]: true,
        })}>
            <Settings
                display={displaySettings}
                close={() => setDisplaySettings(false)}
                saveInitialDate={saveInitialDate}
            />
            <div className={classNames({
                'panel': true,
            })}>
                <DateSwitcher type={layouts[layout].label} date={date} saveInitialDate={saveInitialDate} setDate={saveInitialDateToStore} />
                <div className="settings-switcher-container">
                    {eventDeletion &&
                        <div className="delete-events-settings">
                            {(!isDeletionConfirmed && eventsToDelete.length > 0) &&
                                <button type="button" className="btn-event-delete-confirm" onClick={alertEventDeletion}>
                                    <i className={classNames({
                                        "icon-bin-gray": true,
                                    })} />
                                    <div>Удалить {eventsToDelete.length > 0 && <span dangerouslySetInnerHTML={{ __html: `(` + eventsToDelete.length + `)` }}></span>}</div>
                                </button>
                            }
                            {isDeletionConfirmed &&
                                <button type="button" className="btn-event-delete" onClick={deleteSelectedEvents} >
                                    <i className={classNames({
                                        "icon-bin": true,
                                    })} />
                                    Да, удалить
                                </button>
                            }

                            <button type="button" className="btn-event-delete-cancel" onClick={cancelEventDeletion}>
                                Отменить
                            </button>


                        </div>
                    }
                    <LayoutSwitcher layout={layout} saveLayout={saveLayout} />
                    <span className={
                        classNames({ 'settings-icon': true, 'blue': themeColor === 'blue' })
                    } onClick={() => setDisplaySettings(true)}><img src={design == 'Skeuomorphism' ? BurgerSettings : IconSettings} alt="settings" /></span>
                </div>
            </div>
            {layout >= 2 && <CalendarLayout
                initialDate={date}
                layoutOnClick={layoutOnClick}
                onMonthClick={onMonthClick}
                period={layouts[layout].period}
                setSelectedDate={(date) => setSelectedDate(date)} />}
            {layout === 1 && design === 'Skeuomorphism' &&
                <WeekSkeuomorphism
                    initialDate={date}
                    layoutOnClick={layoutOnClick}
                    onMonthClick={onMonthClick}
                    period={layouts[layout].period}
                    setSelectedDate={(date) => setSelectedDate(date)} />}
            {layout === 1 && design === 'Original' &&
                <Week date={date} />
            }
            {layout === 0 && <div className="day-container"><DayAsWindow date={date} /></div>}


        </div >
    )
}

export { CalendarApp };