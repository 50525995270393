import { createSlice } from "@reduxjs/toolkit";

export const colorSlice = createSlice({
    name: 'colors',
    initialState: {
        colors: [
            { color: '#4F5252' },
            { color: '#FFFFFF', placeholder: '#9FA3A3' }, // displayColor substitutes the color when saved
            { color: '#069685' },
            { color: '#2F80ED' },
            { color: '#9263F5' },
            { color: '#EB5757' },
            { color: '#F5E663' },
            { color: '#FF825A' },
        ],
    },
    reducers: {
        fetchedColors: (state, action) => {
            state.colors = action.payload;
        }
    },
})

export const {
    fetchedColors
} = colorSlice.actions;

export default colorSlice.reducer;
