import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ru from './translations/ru.json';
import en from './translations/en.json';

const resources = {
    ru: {
        translation: ru
    },
    en: {
        translation: en
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'ru',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;